/* eslint-disable */
var proto = { looprpc: {} };

// source: loop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var swapserverrpc_common_pb = require('./swapserverrpc/common_pb.js');
goog.object.extend(proto, swapserverrpc_common_pb);
goog.exportSymbol('proto.looprpc.AbandonSwapRequest', null, global);
goog.exportSymbol('proto.looprpc.AbandonSwapResponse', null, global);
goog.exportSymbol('proto.looprpc.AddressType', null, global);
goog.exportSymbol('proto.looprpc.AutoReason', null, global);
goog.exportSymbol('proto.looprpc.ClientReservation', null, global);
goog.exportSymbol('proto.looprpc.Disqualified', null, global);
goog.exportSymbol('proto.looprpc.FailureReason', null, global);
goog.exportSymbol('proto.looprpc.GetInfoRequest', null, global);
goog.exportSymbol('proto.looprpc.GetInfoResponse', null, global);
goog.exportSymbol('proto.looprpc.GetLiquidityParamsRequest', null, global);
goog.exportSymbol('proto.looprpc.InQuoteResponse', null, global);
goog.exportSymbol('proto.looprpc.InTermsResponse', null, global);
goog.exportSymbol('proto.looprpc.InstantOut', null, global);
goog.exportSymbol('proto.looprpc.InstantOutQuoteRequest', null, global);
goog.exportSymbol('proto.looprpc.InstantOutQuoteResponse', null, global);
goog.exportSymbol('proto.looprpc.InstantOutRequest', null, global);
goog.exportSymbol('proto.looprpc.InstantOutResponse', null, global);
goog.exportSymbol('proto.looprpc.L402Token', null, global);
goog.exportSymbol('proto.looprpc.LiquidityParameters', null, global);
goog.exportSymbol('proto.looprpc.LiquidityRule', null, global);
goog.exportSymbol('proto.looprpc.LiquidityRuleType', null, global);
goog.exportSymbol('proto.looprpc.ListInstantOutsRequest', null, global);
goog.exportSymbol('proto.looprpc.ListInstantOutsResponse', null, global);
goog.exportSymbol('proto.looprpc.ListReservationsRequest', null, global);
goog.exportSymbol('proto.looprpc.ListReservationsResponse', null, global);
goog.exportSymbol('proto.looprpc.ListSwapsFilter', null, global);
goog.exportSymbol('proto.looprpc.ListSwapsFilter.SwapTypeFilter', null, global);
goog.exportSymbol('proto.looprpc.ListSwapsRequest', null, global);
goog.exportSymbol('proto.looprpc.ListSwapsResponse', null, global);
goog.exportSymbol('proto.looprpc.LoopInRequest', null, global);
goog.exportSymbol('proto.looprpc.LoopOutRequest', null, global);
goog.exportSymbol('proto.looprpc.LoopStats', null, global);
goog.exportSymbol('proto.looprpc.MonitorRequest', null, global);
goog.exportSymbol('proto.looprpc.OutQuoteResponse', null, global);
goog.exportSymbol('proto.looprpc.OutTermsResponse', null, global);
goog.exportSymbol('proto.looprpc.ProbeRequest', null, global);
goog.exportSymbol('proto.looprpc.ProbeResponse', null, global);
goog.exportSymbol('proto.looprpc.QuoteRequest', null, global);
goog.exportSymbol('proto.looprpc.SetLiquidityParamsRequest', null, global);
goog.exportSymbol('proto.looprpc.SetLiquidityParamsResponse', null, global);
goog.exportSymbol('proto.looprpc.SuggestSwapsRequest', null, global);
goog.exportSymbol('proto.looprpc.SuggestSwapsResponse', null, global);
goog.exportSymbol('proto.looprpc.SwapInfoRequest', null, global);
goog.exportSymbol('proto.looprpc.SwapResponse', null, global);
goog.exportSymbol('proto.looprpc.SwapState', null, global);
goog.exportSymbol('proto.looprpc.SwapStatus', null, global);
goog.exportSymbol('proto.looprpc.SwapType', null, global);
goog.exportSymbol('proto.looprpc.TermsRequest', null, global);
goog.exportSymbol('proto.looprpc.TokensRequest', null, global);
goog.exportSymbol('proto.looprpc.TokensResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.LoopOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.LoopOutRequest.repeatedFields_, null);
};
goog.inherits(proto.looprpc.LoopOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.LoopOutRequest.displayName = 'proto.looprpc.LoopOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.LoopInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.LoopInRequest.repeatedFields_, null);
};
goog.inherits(proto.looprpc.LoopInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.LoopInRequest.displayName = 'proto.looprpc.LoopInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SwapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.SwapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SwapResponse.displayName = 'proto.looprpc.SwapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.MonitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.MonitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.MonitorRequest.displayName = 'proto.looprpc.MonitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SwapStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.SwapStatus.repeatedFields_, null);
};
goog.inherits(proto.looprpc.SwapStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SwapStatus.displayName = 'proto.looprpc.SwapStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListSwapsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.ListSwapsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListSwapsRequest.displayName = 'proto.looprpc.ListSwapsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListSwapsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.ListSwapsFilter.repeatedFields_, null);
};
goog.inherits(proto.looprpc.ListSwapsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListSwapsFilter.displayName = 'proto.looprpc.ListSwapsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListSwapsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.ListSwapsResponse.repeatedFields_, null);
};
goog.inherits(proto.looprpc.ListSwapsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListSwapsResponse.displayName = 'proto.looprpc.ListSwapsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SwapInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.SwapInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SwapInfoRequest.displayName = 'proto.looprpc.SwapInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.TermsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.TermsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.TermsRequest.displayName = 'proto.looprpc.TermsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InTermsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.InTermsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InTermsResponse.displayName = 'proto.looprpc.InTermsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.OutTermsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.OutTermsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.OutTermsResponse.displayName = 'proto.looprpc.OutTermsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.QuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.QuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.looprpc.QuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.QuoteRequest.displayName = 'proto.looprpc.QuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.InQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InQuoteResponse.displayName = 'proto.looprpc.InQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.OutQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.OutQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.OutQuoteResponse.displayName = 'proto.looprpc.OutQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ProbeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.ProbeRequest.repeatedFields_, null);
};
goog.inherits(proto.looprpc.ProbeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ProbeRequest.displayName = 'proto.looprpc.ProbeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ProbeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.ProbeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ProbeResponse.displayName = 'proto.looprpc.ProbeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.TokensRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.TokensRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.TokensRequest.displayName = 'proto.looprpc.TokensRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.TokensResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.TokensResponse.repeatedFields_, null);
};
goog.inherits(proto.looprpc.TokensResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.TokensResponse.displayName = 'proto.looprpc.TokensResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.L402Token = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.L402Token, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.L402Token.displayName = 'proto.looprpc.L402Token';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.LoopStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.LoopStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.LoopStats.displayName = 'proto.looprpc.LoopStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.GetInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.GetInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.GetInfoRequest.displayName = 'proto.looprpc.GetInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.GetInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.GetInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.GetInfoResponse.displayName = 'proto.looprpc.GetInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.GetLiquidityParamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.GetLiquidityParamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.GetLiquidityParamsRequest.displayName = 'proto.looprpc.GetLiquidityParamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.LiquidityParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.LiquidityParameters.repeatedFields_, null);
};
goog.inherits(proto.looprpc.LiquidityParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.LiquidityParameters.displayName = 'proto.looprpc.LiquidityParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.LiquidityRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.LiquidityRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.LiquidityRule.displayName = 'proto.looprpc.LiquidityRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SetLiquidityParamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.SetLiquidityParamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SetLiquidityParamsRequest.displayName = 'proto.looprpc.SetLiquidityParamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SetLiquidityParamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.SetLiquidityParamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SetLiquidityParamsResponse.displayName = 'proto.looprpc.SetLiquidityParamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SuggestSwapsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.SuggestSwapsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SuggestSwapsRequest.displayName = 'proto.looprpc.SuggestSwapsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.Disqualified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.Disqualified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.Disqualified.displayName = 'proto.looprpc.Disqualified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.SuggestSwapsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.SuggestSwapsResponse.repeatedFields_, null);
};
goog.inherits(proto.looprpc.SuggestSwapsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.SuggestSwapsResponse.displayName = 'proto.looprpc.SuggestSwapsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.AbandonSwapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.AbandonSwapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.AbandonSwapRequest.displayName = 'proto.looprpc.AbandonSwapRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.AbandonSwapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.AbandonSwapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.AbandonSwapResponse.displayName = 'proto.looprpc.AbandonSwapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.ListReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListReservationsRequest.displayName = 'proto.looprpc.ListReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.ListReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.looprpc.ListReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListReservationsResponse.displayName = 'proto.looprpc.ListReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ClientReservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.ClientReservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ClientReservation.displayName = 'proto.looprpc.ClientReservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InstantOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.InstantOutRequest.repeatedFields_, null);
};
goog.inherits(proto.looprpc.InstantOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InstantOutRequest.displayName = 'proto.looprpc.InstantOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InstantOutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.InstantOutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InstantOutResponse.displayName = 'proto.looprpc.InstantOutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InstantOutQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.InstantOutQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InstantOutQuoteRequest.displayName = 'proto.looprpc.InstantOutQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InstantOutQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.InstantOutQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InstantOutQuoteResponse.displayName = 'proto.looprpc.InstantOutQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListInstantOutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.looprpc.ListInstantOutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListInstantOutsRequest.displayName = 'proto.looprpc.ListInstantOutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.ListInstantOutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.ListInstantOutsResponse.repeatedFields_, null);
};
goog.inherits(proto.looprpc.ListInstantOutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.ListInstantOutsResponse.displayName = 'proto.looprpc.ListInstantOutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.looprpc.InstantOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.looprpc.InstantOut.repeatedFields_, null);
};
goog.inherits(proto.looprpc.InstantOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.looprpc.InstantOut.displayName = 'proto.looprpc.InstantOut';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.LoopOutRequest.repeatedFields_ = [11,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.LoopOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.LoopOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.LoopOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    dest: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxSwapRoutingFee: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    maxPrepayRoutingFee: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    maxSwapFee: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    maxPrepayAmt: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    maxMinerFee: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    loopOutChannel: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    outgoingChanSetList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    sweepConfTarget: jspb.Message.getFieldWithDefault(msg, 9, 0),
    htlcConfirmations: jspb.Message.getFieldWithDefault(msg, 13, 0),
    swapPublicationDeadline: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    label: jspb.Message.getFieldWithDefault(msg, 12, ""),
    initiator: jspb.Message.getFieldWithDefault(msg, 14, ""),
    account: jspb.Message.getFieldWithDefault(msg, 15, ""),
    accountAddrType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    isExternalAddr: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    reservationIdsList: msg.getReservationIdsList_asB64(),
    paymentTimeout: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.LoopOutRequest}
 */
proto.looprpc.LoopOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.LoopOutRequest;
  return proto.looprpc.LoopOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.LoopOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.LoopOutRequest}
 */
proto.looprpc.LoopOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxSwapRoutingFee(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxPrepayRoutingFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxSwapFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxPrepayAmt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxMinerFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setLoopOutChannel(value);
      break;
    case 11:
      var values = /** @type {!Array<string>} */ (reader.isDelimited() ? reader.readPackedUint64String() : [reader.readUint64String()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutgoingChanSet(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSweepConfTarget(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHtlcConfirmations(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSwapPublicationDeadline(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiator(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 16:
      var value = /** @type {!proto.looprpc.AddressType} */ (reader.readEnum());
      msg.setAccountAddrType(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExternalAddr(value);
      break;
    case 18:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addReservationIds(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.LoopOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.LoopOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.LoopOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getDest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxSwapRoutingFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getMaxPrepayRoutingFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getMaxSwapFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getMaxPrepayAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getMaxMinerFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getLoopOutChannel();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      8,
      f
    );
  }
  f = message.getOutgoingChanSetList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      11,
      f
    );
  }
  f = message.getSweepConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getHtlcConfirmations();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSwapPublicationDeadline();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      10,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInitiator();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAccountAddrType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getIsExternalAddr();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getReservationIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      18,
      f
    );
  }
  f = message.getPaymentTimeout();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional string dest = 2;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getDest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setDest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 max_swap_routing_fee = 3;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getMaxSwapRoutingFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setMaxSwapRoutingFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 max_prepay_routing_fee = 4;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getMaxPrepayRoutingFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setMaxPrepayRoutingFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 max_swap_fee = 5;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getMaxSwapFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setMaxSwapFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 max_prepay_amt = 6;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getMaxPrepayAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setMaxPrepayAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 max_miner_fee = 7;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getMaxMinerFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setMaxMinerFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint64 loop_out_channel = 8;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getLoopOutChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setLoopOutChannel = function(value) {
  return jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * repeated uint64 outgoing_chan_set = 11;
 * @return {!Array<string>}
 */
proto.looprpc.LoopOutRequest.prototype.getOutgoingChanSetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setOutgoingChanSetList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.addOutgoingChanSet = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.clearOutgoingChanSetList = function() {
  return this.setOutgoingChanSetList([]);
};


/**
 * optional int32 sweep_conf_target = 9;
 * @return {number}
 */
proto.looprpc.LoopOutRequest.prototype.getSweepConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setSweepConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 htlc_confirmations = 13;
 * @return {number}
 */
proto.looprpc.LoopOutRequest.prototype.getHtlcConfirmations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setHtlcConfirmations = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 swap_publication_deadline = 10;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getSwapPublicationDeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setSwapPublicationDeadline = function(value) {
  return jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional string label = 12;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string initiator = 14;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getInitiator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setInitiator = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string account = 15;
 * @return {string}
 */
proto.looprpc.LoopOutRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional AddressType account_addr_type = 16;
 * @return {!proto.looprpc.AddressType}
 */
proto.looprpc.LoopOutRequest.prototype.getAccountAddrType = function() {
  return /** @type {!proto.looprpc.AddressType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.looprpc.AddressType} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setAccountAddrType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional bool is_external_addr = 17;
 * @return {boolean}
 */
proto.looprpc.LoopOutRequest.prototype.getIsExternalAddr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setIsExternalAddr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * repeated bytes reservation_ids = 18;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.looprpc.LoopOutRequest.prototype.getReservationIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * repeated bytes reservation_ids = 18;
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<string>}
 */
proto.looprpc.LoopOutRequest.prototype.getReservationIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getReservationIdsList()));
};


/**
 * repeated bytes reservation_ids = 18;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.looprpc.LoopOutRequest.prototype.getReservationIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getReservationIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setReservationIdsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.addReservationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.clearReservationIdsList = function() {
  return this.setReservationIdsList([]);
};


/**
 * optional uint32 payment_timeout = 19;
 * @return {number}
 */
proto.looprpc.LoopOutRequest.prototype.getPaymentTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LoopOutRequest} returns this
 */
proto.looprpc.LoopOutRequest.prototype.setPaymentTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.LoopInRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.LoopInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.LoopInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.LoopInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    maxSwapFee: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    maxMinerFee: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    lastHop: msg.getLastHop_asB64(),
    externalHtlc: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    htlcConfTarget: jspb.Message.getFieldWithDefault(msg, 6, 0),
    label: jspb.Message.getFieldWithDefault(msg, 7, ""),
    initiator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
    swapserverrpc_common_pb.RouteHint.toObject, includeInstance),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.LoopInRequest}
 */
proto.looprpc.LoopInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.LoopInRequest;
  return proto.looprpc.LoopInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.LoopInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.LoopInRequest}
 */
proto.looprpc.LoopInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxSwapFee(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxMinerFee(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastHop(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalHtlc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHtlcConfTarget(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiator(value);
      break;
    case 9:
      var value = new swapserverrpc_common_pb.RouteHint;
      reader.readMessage(value,swapserverrpc_common_pb.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.LoopInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.LoopInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.LoopInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getMaxSwapFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getMaxMinerFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getLastHop_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getExternalHtlc();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHtlcConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInitiator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      swapserverrpc_common_pb.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 max_swap_fee = 2;
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getMaxSwapFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setMaxSwapFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 max_miner_fee = 3;
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getMaxMinerFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setMaxMinerFee = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bytes last_hop = 4;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.LoopInRequest.prototype.getLastHop = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes last_hop = 4;
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getLastHop_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastHop()));
};


/**
 * optional bytes last_hop = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {!Uint8Array}
 */
proto.looprpc.LoopInRequest.prototype.getLastHop_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastHop()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setLastHop = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bool external_htlc = 5;
 * @return {boolean}
 */
proto.looprpc.LoopInRequest.prototype.getExternalHtlc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setExternalHtlc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 htlc_conf_target = 6;
 * @return {number}
 */
proto.looprpc.LoopInRequest.prototype.getHtlcConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setHtlcConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string initiator = 8;
 * @return {string}
 */
proto.looprpc.LoopInRequest.prototype.getInitiator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setInitiator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated RouteHint route_hints = 9;
 * @return {!Array<!proto.looprpc.RouteHint>}
 */
proto.looprpc.LoopInRequest.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.looprpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, swapserverrpc_common_pb.RouteHint, 9));
};


/**
 * @param {!Array<!proto.looprpc.RouteHint>} value
 * @return {!proto.looprpc.LoopInRequest} returns this
*/
proto.looprpc.LoopInRequest.prototype.setRouteHintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.looprpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.RouteHint}
 */
proto.looprpc.LoopInRequest.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.looprpc.RouteHint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.clearRouteHintsList = function() {
  return this.setRouteHintsList([]);
};


/**
 * optional bool private = 10;
 * @return {boolean}
 */
proto.looprpc.LoopInRequest.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.LoopInRequest} returns this
 */
proto.looprpc.LoopInRequest.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SwapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SwapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SwapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idBytes: msg.getIdBytes_asB64(),
    htlcAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    htlcAddressP2wsh: jspb.Message.getFieldWithDefault(msg, 5, ""),
    htlcAddressP2tr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    serverMessage: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SwapResponse}
 */
proto.looprpc.SwapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SwapResponse;
  return proto.looprpc.SwapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SwapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SwapResponse}
 */
proto.looprpc.SwapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIdBytes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddressP2wsh(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddressP2tr(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SwapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SwapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SwapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getHtlcAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHtlcAddressP2wsh();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHtlcAddressP2tr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getServerMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes id_bytes = 3;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.SwapResponse.prototype.getIdBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes id_bytes = 3;
 * This is a type-conversion wrapper around `getIdBytes()`
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getIdBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIdBytes()));
};


/**
 * optional bytes id_bytes = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIdBytes()`
 * @return {!Uint8Array}
 */
proto.looprpc.SwapResponse.prototype.getIdBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIdBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setIdBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string htlc_address = 2;
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getHtlcAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setHtlcAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string htlc_address_p2wsh = 5;
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getHtlcAddressP2wsh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setHtlcAddressP2wsh = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string htlc_address_p2tr = 7;
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getHtlcAddressP2tr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setHtlcAddressP2tr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string server_message = 6;
 * @return {string}
 */
proto.looprpc.SwapResponse.prototype.getServerMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapResponse} returns this
 */
proto.looprpc.SwapResponse.prototype.setServerMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.MonitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.MonitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.MonitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.MonitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.MonitorRequest}
 */
proto.looprpc.MonitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.MonitorRequest;
  return proto.looprpc.MonitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.MonitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.MonitorRequest}
 */
proto.looprpc.MonitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.MonitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.MonitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.MonitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.MonitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.SwapStatus.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SwapStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SwapStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SwapStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    idBytes: msg.getIdBytes_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    failureReason: jspb.Message.getFieldWithDefault(msg, 14, 0),
    initiationTime: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    lastUpdateTime: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    htlcAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    htlcAddressP2wsh: jspb.Message.getFieldWithDefault(msg, 12, ""),
    htlcAddressP2tr: jspb.Message.getFieldWithDefault(msg, 18, ""),
    costServer: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    costOnchain: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    costOffchain: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    lastHop: msg.getLastHop_asB64(),
    outgoingChanSetList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    label: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SwapStatus}
 */
proto.looprpc.SwapStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SwapStatus;
  return proto.looprpc.SwapStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SwapStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SwapStatus}
 */
proto.looprpc.SwapStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIdBytes(value);
      break;
    case 3:
      var value = /** @type {!proto.looprpc.SwapType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.looprpc.SwapState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 14:
      var value = /** @type {!proto.looprpc.FailureReason} */ (reader.readEnum());
      msg.setFailureReason(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setInitiationTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLastUpdateTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddress(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddressP2wsh(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtlcAddressP2tr(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCostServer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCostOnchain(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCostOffchain(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastHop(value);
      break;
    case 17:
      var values = /** @type {!Array<string>} */ (reader.isDelimited() ? reader.readPackedUint64String() : [reader.readUint64String()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutgoingChanSet(values[i]);
      }
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SwapStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SwapStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SwapStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFailureReason();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getInitiationTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getLastUpdateTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getHtlcAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHtlcAddressP2wsh();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHtlcAddressP2tr();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCostServer();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getCostOnchain();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getCostOffchain();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
  f = message.getLastHop_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = message.getOutgoingChanSetList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      17,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes id_bytes = 11;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.SwapStatus.prototype.getIdBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes id_bytes = 11;
 * This is a type-conversion wrapper around `getIdBytes()`
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getIdBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIdBytes()));
};


/**
 * optional bytes id_bytes = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIdBytes()`
 * @return {!Uint8Array}
 */
proto.looprpc.SwapStatus.prototype.getIdBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIdBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setIdBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional SwapType type = 3;
 * @return {!proto.looprpc.SwapType}
 */
proto.looprpc.SwapStatus.prototype.getType = function() {
  return /** @type {!proto.looprpc.SwapType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.looprpc.SwapType} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SwapState state = 4;
 * @return {!proto.looprpc.SwapState}
 */
proto.looprpc.SwapStatus.prototype.getState = function() {
  return /** @type {!proto.looprpc.SwapState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.looprpc.SwapState} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional FailureReason failure_reason = 14;
 * @return {!proto.looprpc.FailureReason}
 */
proto.looprpc.SwapStatus.prototype.getFailureReason = function() {
  return /** @type {!proto.looprpc.FailureReason} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.looprpc.FailureReason} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setFailureReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int64 initiation_time = 5;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getInitiationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setInitiationTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 last_update_time = 6;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getLastUpdateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setLastUpdateTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional string htlc_address = 7;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getHtlcAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setHtlcAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string htlc_address_p2wsh = 12;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getHtlcAddressP2wsh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setHtlcAddressP2wsh = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string htlc_address_p2tr = 18;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getHtlcAddressP2tr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setHtlcAddressP2tr = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 cost_server = 8;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getCostServer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setCostServer = function(value) {
  return jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional int64 cost_onchain = 9;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getCostOnchain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setCostOnchain = function(value) {
  return jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional int64 cost_offchain = 10;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getCostOffchain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setCostOffchain = function(value) {
  return jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional bytes last_hop = 16;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.SwapStatus.prototype.getLastHop = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes last_hop = 16;
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getLastHop_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastHop()));
};


/**
 * optional bytes last_hop = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {!Uint8Array}
 */
proto.looprpc.SwapStatus.prototype.getLastHop_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastHop()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setLastHop = function(value) {
  return jspb.Message.setProto3BytesField(this, 16, value);
};


/**
 * repeated uint64 outgoing_chan_set = 17;
 * @return {!Array<string>}
 */
proto.looprpc.SwapStatus.prototype.getOutgoingChanSetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setOutgoingChanSetList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.addOutgoingChanSet = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.clearOutgoingChanSetList = function() {
  return this.setOutgoingChanSetList([]);
};


/**
 * optional string label = 15;
 * @return {string}
 */
proto.looprpc.SwapStatus.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.SwapStatus} returns this
 */
proto.looprpc.SwapStatus.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListSwapsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListSwapsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListSwapsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listSwapFilter: (f = msg.getListSwapFilter()) && proto.looprpc.ListSwapsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListSwapsRequest}
 */
proto.looprpc.ListSwapsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListSwapsRequest;
  return proto.looprpc.ListSwapsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListSwapsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListSwapsRequest}
 */
proto.looprpc.ListSwapsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.ListSwapsFilter;
      reader.readMessage(value,proto.looprpc.ListSwapsFilter.deserializeBinaryFromReader);
      msg.setListSwapFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListSwapsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListSwapsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListSwapsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListSwapFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.looprpc.ListSwapsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListSwapsFilter list_swap_filter = 1;
 * @return {?proto.looprpc.ListSwapsFilter}
 */
proto.looprpc.ListSwapsRequest.prototype.getListSwapFilter = function() {
  return /** @type{?proto.looprpc.ListSwapsFilter} */ (
    jspb.Message.getWrapperField(this, proto.looprpc.ListSwapsFilter, 1));
};


/**
 * @param {?proto.looprpc.ListSwapsFilter|undefined} value
 * @return {!proto.looprpc.ListSwapsRequest} returns this
*/
proto.looprpc.ListSwapsRequest.prototype.setListSwapFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.looprpc.ListSwapsRequest} returns this
 */
proto.looprpc.ListSwapsRequest.prototype.clearListSwapFilter = function() {
  return this.setListSwapFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.looprpc.ListSwapsRequest.prototype.hasListSwapFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.ListSwapsFilter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListSwapsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListSwapsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListSwapsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pendingOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    outgoingChanSetList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loopInLastHop: msg.getLoopInLastHop_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListSwapsFilter}
 */
proto.looprpc.ListSwapsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListSwapsFilter;
  return proto.looprpc.ListSwapsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListSwapsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListSwapsFilter}
 */
proto.looprpc.ListSwapsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.looprpc.ListSwapsFilter.SwapTypeFilter} */ (reader.readEnum());
      msg.setSwapType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingOnly(value);
      break;
    case 3:
      var values = /** @type {!Array<string>} */ (reader.isDelimited() ? reader.readPackedUint64String() : [reader.readUint64String()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutgoingChanSet(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLoopInLastHop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListSwapsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListSwapsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListSwapsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPendingOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOutgoingChanSetList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoopInLastHop_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.looprpc.ListSwapsFilter.SwapTypeFilter = {
  ANY: 0,
  LOOP_OUT: 1,
  LOOP_IN: 2
};

/**
 * optional SwapTypeFilter swap_type = 1;
 * @return {!proto.looprpc.ListSwapsFilter.SwapTypeFilter}
 */
proto.looprpc.ListSwapsFilter.prototype.getSwapType = function() {
  return /** @type {!proto.looprpc.ListSwapsFilter.SwapTypeFilter} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.looprpc.ListSwapsFilter.SwapTypeFilter} value
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.setSwapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool pending_only = 2;
 * @return {boolean}
 */
proto.looprpc.ListSwapsFilter.prototype.getPendingOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.setPendingOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated uint64 outgoing_chan_set = 3;
 * @return {!Array<string>}
 */
proto.looprpc.ListSwapsFilter.prototype.getOutgoingChanSetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.setOutgoingChanSetList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.addOutgoingChanSet = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.clearOutgoingChanSetList = function() {
  return this.setOutgoingChanSetList([]);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.looprpc.ListSwapsFilter.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes loop_in_last_hop = 5;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.ListSwapsFilter.prototype.getLoopInLastHop = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes loop_in_last_hop = 5;
 * This is a type-conversion wrapper around `getLoopInLastHop()`
 * @return {string}
 */
proto.looprpc.ListSwapsFilter.prototype.getLoopInLastHop_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLoopInLastHop()));
};


/**
 * optional bytes loop_in_last_hop = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLoopInLastHop()`
 * @return {!Uint8Array}
 */
proto.looprpc.ListSwapsFilter.prototype.getLoopInLastHop_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLoopInLastHop()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.ListSwapsFilter} returns this
 */
proto.looprpc.ListSwapsFilter.prototype.setLoopInLastHop = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.ListSwapsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListSwapsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListSwapsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListSwapsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapsList: jspb.Message.toObjectList(msg.getSwapsList(),
    proto.looprpc.SwapStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListSwapsResponse}
 */
proto.looprpc.ListSwapsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListSwapsResponse;
  return proto.looprpc.ListSwapsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListSwapsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListSwapsResponse}
 */
proto.looprpc.ListSwapsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.SwapStatus;
      reader.readMessage(value,proto.looprpc.SwapStatus.deserializeBinaryFromReader);
      msg.addSwaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListSwapsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListSwapsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListSwapsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListSwapsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.SwapStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SwapStatus swaps = 1;
 * @return {!Array<!proto.looprpc.SwapStatus>}
 */
proto.looprpc.ListSwapsResponse.prototype.getSwapsList = function() {
  return /** @type{!Array<!proto.looprpc.SwapStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.SwapStatus, 1));
};


/**
 * @param {!Array<!proto.looprpc.SwapStatus>} value
 * @return {!proto.looprpc.ListSwapsResponse} returns this
*/
proto.looprpc.ListSwapsResponse.prototype.setSwapsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.SwapStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.SwapStatus}
 */
proto.looprpc.ListSwapsResponse.prototype.addSwaps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.SwapStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.ListSwapsResponse} returns this
 */
proto.looprpc.ListSwapsResponse.prototype.clearSwapsList = function() {
  return this.setSwapsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SwapInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SwapInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SwapInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SwapInfoRequest}
 */
proto.looprpc.SwapInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SwapInfoRequest;
  return proto.looprpc.SwapInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SwapInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SwapInfoRequest}
 */
proto.looprpc.SwapInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SwapInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SwapInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SwapInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SwapInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.SwapInfoRequest.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.looprpc.SwapInfoRequest.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.looprpc.SwapInfoRequest.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.SwapInfoRequest} returns this
 */
proto.looprpc.SwapInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.TermsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.TermsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.TermsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TermsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.TermsRequest}
 */
proto.looprpc.TermsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.TermsRequest;
  return proto.looprpc.TermsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.TermsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.TermsRequest}
 */
proto.looprpc.TermsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.TermsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.TermsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.TermsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TermsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InTermsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InTermsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InTermsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InTermsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    minSwapAmount: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    maxSwapAmount: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InTermsResponse}
 */
proto.looprpc.InTermsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InTermsResponse;
  return proto.looprpc.InTermsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InTermsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InTermsResponse}
 */
proto.looprpc.InTermsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinSwapAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxSwapAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InTermsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InTermsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InTermsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InTermsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getMaxSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
};


/**
 * optional int64 min_swap_amount = 5;
 * @return {string}
 */
proto.looprpc.InTermsResponse.prototype.getMinSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InTermsResponse} returns this
 */
proto.looprpc.InTermsResponse.prototype.setMinSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 max_swap_amount = 6;
 * @return {string}
 */
proto.looprpc.InTermsResponse.prototype.getMaxSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InTermsResponse} returns this
 */
proto.looprpc.InTermsResponse.prototype.setMaxSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.OutTermsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.OutTermsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.OutTermsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.OutTermsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    minSwapAmount: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    maxSwapAmount: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    minCltvDelta: jspb.Message.getFieldWithDefault(msg, 8, 0),
    maxCltvDelta: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.OutTermsResponse}
 */
proto.looprpc.OutTermsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.OutTermsResponse;
  return proto.looprpc.OutTermsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.OutTermsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.OutTermsResponse}
 */
proto.looprpc.OutTermsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinSwapAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxSwapAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinCltvDelta(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCltvDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.OutTermsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.OutTermsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.OutTermsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.OutTermsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getMaxSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getMinCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMaxCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 min_swap_amount = 5;
 * @return {string}
 */
proto.looprpc.OutTermsResponse.prototype.getMinSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.OutTermsResponse} returns this
 */
proto.looprpc.OutTermsResponse.prototype.setMinSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 max_swap_amount = 6;
 * @return {string}
 */
proto.looprpc.OutTermsResponse.prototype.getMaxSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.OutTermsResponse} returns this
 */
proto.looprpc.OutTermsResponse.prototype.setMaxSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int32 min_cltv_delta = 8;
 * @return {number}
 */
proto.looprpc.OutTermsResponse.prototype.getMinCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.OutTermsResponse} returns this
 */
proto.looprpc.OutTermsResponse.prototype.setMinCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 max_cltv_delta = 9;
 * @return {number}
 */
proto.looprpc.OutTermsResponse.prototype.getMaxCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.OutTermsResponse} returns this
 */
proto.looprpc.OutTermsResponse.prototype.setMaxCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.QuoteRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.QuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.QuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.QuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.QuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    confTarget: jspb.Message.getFieldWithDefault(msg, 2, 0),
    externalHtlc: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    swapPublicationDeadline: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    loopInLastHop: msg.getLoopInLastHop_asB64(),
    loopInRouteHintsList: jspb.Message.toObjectList(msg.getLoopInRouteHintsList(),
    swapserverrpc_common_pb.RouteHint.toObject, includeInstance),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.QuoteRequest}
 */
proto.looprpc.QuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.QuoteRequest;
  return proto.looprpc.QuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.QuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.QuoteRequest}
 */
proto.looprpc.QuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConfTarget(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalHtlc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSwapPublicationDeadline(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLoopInLastHop(value);
      break;
    case 6:
      var value = new swapserverrpc_common_pb.RouteHint;
      reader.readMessage(value,swapserverrpc_common_pb.RouteHint.deserializeBinaryFromReader);
      msg.addLoopInRouteHints(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.QuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.QuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.QuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.QuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExternalHtlc();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSwapPublicationDeadline();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getLoopInLastHop_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getLoopInRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      swapserverrpc_common_pb.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.looprpc.QuoteRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int32 conf_target = 2;
 * @return {number}
 */
proto.looprpc.QuoteRequest.prototype.getConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool external_htlc = 3;
 * @return {boolean}
 */
proto.looprpc.QuoteRequest.prototype.getExternalHtlc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setExternalHtlc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint64 swap_publication_deadline = 4;
 * @return {string}
 */
proto.looprpc.QuoteRequest.prototype.getSwapPublicationDeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setSwapPublicationDeadline = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional bytes loop_in_last_hop = 5;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.QuoteRequest.prototype.getLoopInLastHop = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes loop_in_last_hop = 5;
 * This is a type-conversion wrapper around `getLoopInLastHop()`
 * @return {string}
 */
proto.looprpc.QuoteRequest.prototype.getLoopInLastHop_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLoopInLastHop()));
};


/**
 * optional bytes loop_in_last_hop = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLoopInLastHop()`
 * @return {!Uint8Array}
 */
proto.looprpc.QuoteRequest.prototype.getLoopInLastHop_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLoopInLastHop()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setLoopInLastHop = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * repeated RouteHint loop_in_route_hints = 6;
 * @return {!Array<!proto.looprpc.RouteHint>}
 */
proto.looprpc.QuoteRequest.prototype.getLoopInRouteHintsList = function() {
  return /** @type{!Array<!proto.looprpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, swapserverrpc_common_pb.RouteHint, 6));
};


/**
 * @param {!Array<!proto.looprpc.RouteHint>} value
 * @return {!proto.looprpc.QuoteRequest} returns this
*/
proto.looprpc.QuoteRequest.prototype.setLoopInRouteHintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.looprpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.RouteHint}
 */
proto.looprpc.QuoteRequest.prototype.addLoopInRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.looprpc.RouteHint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.clearLoopInRouteHintsList = function() {
  return this.setLoopInRouteHintsList([]);
};


/**
 * optional bool private = 7;
 * @return {boolean}
 */
proto.looprpc.QuoteRequest.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.QuoteRequest} returns this
 */
proto.looprpc.QuoteRequest.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapFeeSat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    htlcPublishFeeSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    cltvDelta: jspb.Message.getFieldWithDefault(msg, 5, 0),
    confTarget: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InQuoteResponse}
 */
proto.looprpc.InQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InQuoteResponse;
  return proto.looprpc.InQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InQuoteResponse}
 */
proto.looprpc.InQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSwapFeeSat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setHtlcPublishFeeSat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCltvDelta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConfTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getHtlcPublishFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int64 swap_fee_sat = 1;
 * @return {string}
 */
proto.looprpc.InQuoteResponse.prototype.getSwapFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InQuoteResponse} returns this
 */
proto.looprpc.InQuoteResponse.prototype.setSwapFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 htlc_publish_fee_sat = 3;
 * @return {string}
 */
proto.looprpc.InQuoteResponse.prototype.getHtlcPublishFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InQuoteResponse} returns this
 */
proto.looprpc.InQuoteResponse.prototype.setHtlcPublishFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int32 cltv_delta = 5;
 * @return {number}
 */
proto.looprpc.InQuoteResponse.prototype.getCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.InQuoteResponse} returns this
 */
proto.looprpc.InQuoteResponse.prototype.setCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 conf_target = 6;
 * @return {number}
 */
proto.looprpc.InQuoteResponse.prototype.getConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.InQuoteResponse} returns this
 */
proto.looprpc.InQuoteResponse.prototype.setConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.OutQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.OutQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.OutQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.OutQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapFeeSat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    prepayAmtSat: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    htlcSweepFeeSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    swapPaymentDest: msg.getSwapPaymentDest_asB64(),
    cltvDelta: jspb.Message.getFieldWithDefault(msg, 5, 0),
    confTarget: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.OutQuoteResponse}
 */
proto.looprpc.OutQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.OutQuoteResponse;
  return proto.looprpc.OutQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.OutQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.OutQuoteResponse}
 */
proto.looprpc.OutQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSwapFeeSat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPrepayAmtSat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setHtlcSweepFeeSat(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSwapPaymentDest(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCltvDelta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConfTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.OutQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.OutQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.OutQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.OutQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getPrepayAmtSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getHtlcSweepFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getSwapPaymentDest_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int64 swap_fee_sat = 1;
 * @return {string}
 */
proto.looprpc.OutQuoteResponse.prototype.getSwapFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setSwapFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 prepay_amt_sat = 2;
 * @return {string}
 */
proto.looprpc.OutQuoteResponse.prototype.getPrepayAmtSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setPrepayAmtSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 htlc_sweep_fee_sat = 3;
 * @return {string}
 */
proto.looprpc.OutQuoteResponse.prototype.getHtlcSweepFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setHtlcSweepFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bytes swap_payment_dest = 4;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.OutQuoteResponse.prototype.getSwapPaymentDest = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes swap_payment_dest = 4;
 * This is a type-conversion wrapper around `getSwapPaymentDest()`
 * @return {string}
 */
proto.looprpc.OutQuoteResponse.prototype.getSwapPaymentDest_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSwapPaymentDest()));
};


/**
 * optional bytes swap_payment_dest = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSwapPaymentDest()`
 * @return {!Uint8Array}
 */
proto.looprpc.OutQuoteResponse.prototype.getSwapPaymentDest_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSwapPaymentDest()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setSwapPaymentDest = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional int32 cltv_delta = 5;
 * @return {number}
 */
proto.looprpc.OutQuoteResponse.prototype.getCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 conf_target = 6;
 * @return {number}
 */
proto.looprpc.OutQuoteResponse.prototype.getConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.OutQuoteResponse} returns this
 */
proto.looprpc.OutQuoteResponse.prototype.setConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.ProbeRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ProbeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ProbeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ProbeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ProbeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    lastHop: msg.getLastHop_asB64(),
    routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
    swapserverrpc_common_pb.RouteHint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ProbeRequest}
 */
proto.looprpc.ProbeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ProbeRequest;
  return proto.looprpc.ProbeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ProbeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ProbeRequest}
 */
proto.looprpc.ProbeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastHop(value);
      break;
    case 3:
      var value = new swapserverrpc_common_pb.RouteHint;
      reader.readMessage(value,swapserverrpc_common_pb.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ProbeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ProbeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ProbeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ProbeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getLastHop_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      swapserverrpc_common_pb.RouteHint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.looprpc.ProbeRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.ProbeRequest} returns this
 */
proto.looprpc.ProbeRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional bytes last_hop = 2;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.ProbeRequest.prototype.getLastHop = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes last_hop = 2;
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {string}
 */
proto.looprpc.ProbeRequest.prototype.getLastHop_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastHop()));
};


/**
 * optional bytes last_hop = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastHop()`
 * @return {!Uint8Array}
 */
proto.looprpc.ProbeRequest.prototype.getLastHop_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastHop()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.ProbeRequest} returns this
 */
proto.looprpc.ProbeRequest.prototype.setLastHop = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated RouteHint route_hints = 3;
 * @return {!Array<!proto.looprpc.RouteHint>}
 */
proto.looprpc.ProbeRequest.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.looprpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, swapserverrpc_common_pb.RouteHint, 3));
};


/**
 * @param {!Array<!proto.looprpc.RouteHint>} value
 * @return {!proto.looprpc.ProbeRequest} returns this
*/
proto.looprpc.ProbeRequest.prototype.setRouteHintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.looprpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.RouteHint}
 */
proto.looprpc.ProbeRequest.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.looprpc.RouteHint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.ProbeRequest} returns this
 */
proto.looprpc.ProbeRequest.prototype.clearRouteHintsList = function() {
  return this.setRouteHintsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ProbeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ProbeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ProbeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ProbeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ProbeResponse}
 */
proto.looprpc.ProbeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ProbeResponse;
  return proto.looprpc.ProbeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ProbeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ProbeResponse}
 */
proto.looprpc.ProbeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ProbeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ProbeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ProbeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ProbeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.TokensRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.TokensRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.TokensRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TokensRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.TokensRequest}
 */
proto.looprpc.TokensRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.TokensRequest;
  return proto.looprpc.TokensRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.TokensRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.TokensRequest}
 */
proto.looprpc.TokensRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.TokensRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.TokensRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.TokensRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TokensRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.TokensResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.TokensResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.TokensResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.TokensResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TokensResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokensList: jspb.Message.toObjectList(msg.getTokensList(),
    proto.looprpc.L402Token.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.TokensResponse}
 */
proto.looprpc.TokensResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.TokensResponse;
  return proto.looprpc.TokensResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.TokensResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.TokensResponse}
 */
proto.looprpc.TokensResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.L402Token;
      reader.readMessage(value,proto.looprpc.L402Token.deserializeBinaryFromReader);
      msg.addTokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.TokensResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.TokensResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.TokensResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.TokensResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.L402Token.serializeBinaryToWriter
    );
  }
};


/**
 * repeated L402Token tokens = 1;
 * @return {!Array<!proto.looprpc.L402Token>}
 */
proto.looprpc.TokensResponse.prototype.getTokensList = function() {
  return /** @type{!Array<!proto.looprpc.L402Token>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.L402Token, 1));
};


/**
 * @param {!Array<!proto.looprpc.L402Token>} value
 * @return {!proto.looprpc.TokensResponse} returns this
*/
proto.looprpc.TokensResponse.prototype.setTokensList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.L402Token=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.L402Token}
 */
proto.looprpc.TokensResponse.prototype.addTokens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.L402Token, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.TokensResponse} returns this
 */
proto.looprpc.TokensResponse.prototype.clearTokensList = function() {
  return this.setTokensList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.L402Token.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.L402Token.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.L402Token} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.L402Token.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseMacaroon: msg.getBaseMacaroon_asB64(),
    paymentHash: msg.getPaymentHash_asB64(),
    paymentPreimage: msg.getPaymentPreimage_asB64(),
    amountPaidMsat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    routingFeePaidMsat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    timeCreated: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    storageName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    id: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.L402Token}
 */
proto.looprpc.L402Token.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.L402Token;
  return proto.looprpc.L402Token.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.L402Token} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.L402Token}
 */
proto.looprpc.L402Token.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBaseMacaroon(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentHash(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentPreimage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmountPaidMsat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRoutingFeePaidMsat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimeCreated(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorageName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.L402Token.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.L402Token.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.L402Token} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.L402Token.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseMacaroon_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPaymentHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getPaymentPreimage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAmountPaidMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getRoutingFeePaidMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getTimeCreated();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStorageName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bytes base_macaroon = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.L402Token.prototype.getBaseMacaroon = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes base_macaroon = 1;
 * This is a type-conversion wrapper around `getBaseMacaroon()`
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getBaseMacaroon_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBaseMacaroon()));
};


/**
 * optional bytes base_macaroon = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBaseMacaroon()`
 * @return {!Uint8Array}
 */
proto.looprpc.L402Token.prototype.getBaseMacaroon_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBaseMacaroon()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setBaseMacaroon = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes payment_hash = 2;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.L402Token.prototype.getPaymentHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes payment_hash = 2;
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getPaymentHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentHash()));
};


/**
 * optional bytes payment_hash = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {!Uint8Array}
 */
proto.looprpc.L402Token.prototype.getPaymentHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setPaymentHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes payment_preimage = 3;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.L402Token.prototype.getPaymentPreimage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes payment_preimage = 3;
 * This is a type-conversion wrapper around `getPaymentPreimage()`
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getPaymentPreimage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentPreimage()));
};


/**
 * optional bytes payment_preimage = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentPreimage()`
 * @return {!Uint8Array}
 */
proto.looprpc.L402Token.prototype.getPaymentPreimage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentPreimage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setPaymentPreimage = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 amount_paid_msat = 4;
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getAmountPaidMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setAmountPaidMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 routing_fee_paid_msat = 5;
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getRoutingFeePaidMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setRoutingFeePaidMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 time_created = 6;
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getTimeCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setTimeCreated = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional bool expired = 7;
 * @return {boolean}
 */
proto.looprpc.L402Token.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string storage_name = 8;
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getStorageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setStorageName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id = 9;
 * @return {string}
 */
proto.looprpc.L402Token.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.L402Token} returns this
 */
proto.looprpc.L402Token.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.LoopStats.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.LoopStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.LoopStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingCount: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    successCount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    failCount: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    sumPendingAmt: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    sumSucceededAmt: jspb.Message.getFieldWithDefault(msg, 5, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.LoopStats}
 */
proto.looprpc.LoopStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.LoopStats;
  return proto.looprpc.LoopStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.LoopStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.LoopStats}
 */
proto.looprpc.LoopStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setPendingCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSuccessCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFailCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSumPendingAmt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSumSucceededAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.LoopStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.LoopStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.LoopStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LoopStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingCount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getSuccessCount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getFailCount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getSumPendingAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getSumSucceededAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
};


/**
 * optional uint64 pending_count = 1;
 * @return {string}
 */
proto.looprpc.LoopStats.prototype.getPendingCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopStats} returns this
 */
proto.looprpc.LoopStats.prototype.setPendingCount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 success_count = 2;
 * @return {string}
 */
proto.looprpc.LoopStats.prototype.getSuccessCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopStats} returns this
 */
proto.looprpc.LoopStats.prototype.setSuccessCount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 fail_count = 3;
 * @return {string}
 */
proto.looprpc.LoopStats.prototype.getFailCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopStats} returns this
 */
proto.looprpc.LoopStats.prototype.setFailCount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 sum_pending_amt = 4;
 * @return {string}
 */
proto.looprpc.LoopStats.prototype.getSumPendingAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopStats} returns this
 */
proto.looprpc.LoopStats.prototype.setSumPendingAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 sum_succeeded_amt = 5;
 * @return {string}
 */
proto.looprpc.LoopStats.prototype.getSumSucceededAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LoopStats} returns this
 */
proto.looprpc.LoopStats.prototype.setSumSucceededAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.GetInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.GetInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.GetInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.GetInfoRequest}
 */
proto.looprpc.GetInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.GetInfoRequest;
  return proto.looprpc.GetInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.GetInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.GetInfoRequest}
 */
proto.looprpc.GetInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.GetInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.GetInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.GetInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.GetInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.GetInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.GetInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    network: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rpcListen: jspb.Message.getFieldWithDefault(msg, 3, ""),
    restListen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    macaroonPath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tlsCertPath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    loopOutStats: (f = msg.getLoopOutStats()) && proto.looprpc.LoopStats.toObject(includeInstance, f),
    loopInStats: (f = msg.getLoopInStats()) && proto.looprpc.LoopStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.GetInfoResponse}
 */
proto.looprpc.GetInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.GetInfoResponse;
  return proto.looprpc.GetInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.GetInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.GetInfoResponse}
 */
proto.looprpc.GetInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetwork(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRpcListen(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestListen(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaroonPath(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTlsCertPath(value);
      break;
    case 7:
      var value = new proto.looprpc.LoopStats;
      reader.readMessage(value,proto.looprpc.LoopStats.deserializeBinaryFromReader);
      msg.setLoopOutStats(value);
      break;
    case 8:
      var value = new proto.looprpc.LoopStats;
      reader.readMessage(value,proto.looprpc.LoopStats.deserializeBinaryFromReader);
      msg.setLoopInStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.GetInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.GetInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.GetInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNetwork();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRpcListen();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRestListen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMacaroonPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTlsCertPath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLoopOutStats();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.looprpc.LoopStats.serializeBinaryToWriter
    );
  }
  f = message.getLoopInStats();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.looprpc.LoopStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string network = 2;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getNetwork = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setNetwork = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rpc_listen = 3;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getRpcListen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setRpcListen = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rest_listen = 4;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getRestListen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setRestListen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string macaroon_path = 5;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getMacaroonPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setMacaroonPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tls_cert_path = 6;
 * @return {string}
 */
proto.looprpc.GetInfoResponse.prototype.getTlsCertPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.setTlsCertPath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional LoopStats loop_out_stats = 7;
 * @return {?proto.looprpc.LoopStats}
 */
proto.looprpc.GetInfoResponse.prototype.getLoopOutStats = function() {
  return /** @type{?proto.looprpc.LoopStats} */ (
    jspb.Message.getWrapperField(this, proto.looprpc.LoopStats, 7));
};


/**
 * @param {?proto.looprpc.LoopStats|undefined} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
*/
proto.looprpc.GetInfoResponse.prototype.setLoopOutStats = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.clearLoopOutStats = function() {
  return this.setLoopOutStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.looprpc.GetInfoResponse.prototype.hasLoopOutStats = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LoopStats loop_in_stats = 8;
 * @return {?proto.looprpc.LoopStats}
 */
proto.looprpc.GetInfoResponse.prototype.getLoopInStats = function() {
  return /** @type{?proto.looprpc.LoopStats} */ (
    jspb.Message.getWrapperField(this, proto.looprpc.LoopStats, 8));
};


/**
 * @param {?proto.looprpc.LoopStats|undefined} value
 * @return {!proto.looprpc.GetInfoResponse} returns this
*/
proto.looprpc.GetInfoResponse.prototype.setLoopInStats = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.looprpc.GetInfoResponse} returns this
 */
proto.looprpc.GetInfoResponse.prototype.clearLoopInStats = function() {
  return this.setLoopInStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.looprpc.GetInfoResponse.prototype.hasLoopInStats = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.GetLiquidityParamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.GetLiquidityParamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.GetLiquidityParamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetLiquidityParamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.GetLiquidityParamsRequest}
 */
proto.looprpc.GetLiquidityParamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.GetLiquidityParamsRequest;
  return proto.looprpc.GetLiquidityParamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.GetLiquidityParamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.GetLiquidityParamsRequest}
 */
proto.looprpc.GetLiquidityParamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.GetLiquidityParamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.GetLiquidityParamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.GetLiquidityParamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.GetLiquidityParamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.LiquidityParameters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.LiquidityParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.LiquidityParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.LiquidityParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LiquidityParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.looprpc.LiquidityRule.toObject, includeInstance),
    feePpm: jspb.Message.getFieldWithDefault(msg, 16, "0"),
    sweepFeeRateSatPerVbyte: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    maxSwapFeePpm: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    maxRoutingFeePpm: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    maxPrepayRoutingFeePpm: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    maxPrepaySat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    maxMinerFeeSat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    sweepConfTarget: jspb.Message.getFieldWithDefault(msg, 8, 0),
    failureBackoffSec: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    autoloop: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    autoloopBudgetSat: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    autoloopBudgetStartSec: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    autoMaxInFlight: jspb.Message.getFieldWithDefault(msg, 13, "0"),
    minSwapAmount: jspb.Message.getFieldWithDefault(msg, 14, "0"),
    maxSwapAmount: jspb.Message.getFieldWithDefault(msg, 15, "0"),
    htlcConfTarget: jspb.Message.getFieldWithDefault(msg, 17, 0),
    autoloopDestAddress: jspb.Message.getFieldWithDefault(msg, 18, ""),
    autoloopBudgetRefreshPeriodSec: jspb.Message.getFieldWithDefault(msg, 19, "0"),
    autoloopBudgetLastRefresh: jspb.Message.getFieldWithDefault(msg, 20, "0"),
    easyAutoloop: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    easyAutoloopLocalTargetSat: jspb.Message.getFieldWithDefault(msg, 22, "0"),
    account: jspb.Message.getFieldWithDefault(msg, 23, ""),
    accountAddrType: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.LiquidityParameters}
 */
proto.looprpc.LiquidityParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.LiquidityParameters;
  return proto.looprpc.LiquidityParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.LiquidityParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.LiquidityParameters}
 */
proto.looprpc.LiquidityParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.LiquidityRule;
      reader.readMessage(value,proto.looprpc.LiquidityRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFeePpm(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSweepFeeRateSatPerVbyte(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxSwapFeePpm(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxRoutingFeePpm(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxPrepayRoutingFeePpm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxPrepaySat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxMinerFeeSat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSweepConfTarget(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFailureBackoffSec(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoloop(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAutoloopBudgetSat(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAutoloopBudgetStartSec(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAutoMaxInFlight(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinSwapAmount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxSwapAmount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHtlcConfTarget(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoloopDestAddress(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAutoloopBudgetRefreshPeriodSec(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAutoloopBudgetLastRefresh(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEasyAutoloop(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setEasyAutoloopLocalTargetSat(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 24:
      var value = /** @type {!proto.looprpc.AddressType} */ (reader.readEnum());
      msg.setAccountAddrType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.LiquidityParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.LiquidityParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.LiquidityParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LiquidityParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.LiquidityRule.serializeBinaryToWriter
    );
  }
  f = message.getFeePpm();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      16,
      f
    );
  }
  f = message.getSweepFeeRateSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getMaxSwapFeePpm();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getMaxRoutingFeePpm();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getMaxPrepayRoutingFeePpm();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getMaxPrepaySat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getMaxMinerFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getSweepConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getFailureBackoffSec();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getAutoloop();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAutoloopBudgetSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
  f = message.getAutoloopBudgetStartSec();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      12,
      f
    );
  }
  f = message.getAutoMaxInFlight();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      13,
      f
    );
  }
  f = message.getMinSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      14,
      f
    );
  }
  f = message.getMaxSwapAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      15,
      f
    );
  }
  f = message.getHtlcConfTarget();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getAutoloopDestAddress();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAutoloopBudgetRefreshPeriodSec();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      19,
      f
    );
  }
  f = message.getAutoloopBudgetLastRefresh();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      20,
      f
    );
  }
  f = message.getEasyAutoloop();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getEasyAutoloopLocalTargetSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      22,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAccountAddrType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
};


/**
 * repeated LiquidityRule rules = 1;
 * @return {!Array<!proto.looprpc.LiquidityRule>}
 */
proto.looprpc.LiquidityParameters.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.looprpc.LiquidityRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.LiquidityRule, 1));
};


/**
 * @param {!Array<!proto.looprpc.LiquidityRule>} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
*/
proto.looprpc.LiquidityParameters.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.LiquidityRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.LiquidityRule}
 */
proto.looprpc.LiquidityParameters.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.LiquidityRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional uint64 fee_ppm = 16;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getFeePpm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setFeePpm = function(value) {
  return jspb.Message.setProto3StringIntField(this, 16, value);
};


/**
 * optional uint64 sweep_fee_rate_sat_per_vbyte = 2;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getSweepFeeRateSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setSweepFeeRateSatPerVbyte = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 max_swap_fee_ppm = 3;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxSwapFeePpm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxSwapFeePpm = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint64 max_routing_fee_ppm = 4;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxRoutingFeePpm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxRoutingFeePpm = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 max_prepay_routing_fee_ppm = 5;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxPrepayRoutingFeePpm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxPrepayRoutingFeePpm = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint64 max_prepay_sat = 6;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxPrepaySat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxPrepaySat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 max_miner_fee_sat = 7;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxMinerFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxMinerFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional int32 sweep_conf_target = 8;
 * @return {number}
 */
proto.looprpc.LiquidityParameters.prototype.getSweepConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setSweepConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 failure_backoff_sec = 9;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getFailureBackoffSec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setFailureBackoffSec = function(value) {
  return jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional bool autoloop = 10;
 * @return {boolean}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional uint64 autoloop_budget_sat = 11;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloopBudgetSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloopBudgetSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional uint64 autoloop_budget_start_sec = 12;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloopBudgetStartSec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloopBudgetStartSec = function(value) {
  return jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * optional uint64 auto_max_in_flight = 13;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoMaxInFlight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoMaxInFlight = function(value) {
  return jspb.Message.setProto3StringIntField(this, 13, value);
};


/**
 * optional uint64 min_swap_amount = 14;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMinSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMinSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 14, value);
};


/**
 * optional uint64 max_swap_amount = 15;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getMaxSwapAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setMaxSwapAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 15, value);
};


/**
 * optional int32 htlc_conf_target = 17;
 * @return {number}
 */
proto.looprpc.LiquidityParameters.prototype.getHtlcConfTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setHtlcConfTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string autoloop_dest_address = 18;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloopDestAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloopDestAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional uint64 autoloop_budget_refresh_period_sec = 19;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloopBudgetRefreshPeriodSec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloopBudgetRefreshPeriodSec = function(value) {
  return jspb.Message.setProto3StringIntField(this, 19, value);
};


/**
 * optional uint64 autoloop_budget_last_refresh = 20;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAutoloopBudgetLastRefresh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAutoloopBudgetLastRefresh = function(value) {
  return jspb.Message.setProto3StringIntField(this, 20, value);
};


/**
 * optional bool easy_autoloop = 21;
 * @return {boolean}
 */
proto.looprpc.LiquidityParameters.prototype.getEasyAutoloop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setEasyAutoloop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional uint64 easy_autoloop_local_target_sat = 22;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getEasyAutoloopLocalTargetSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setEasyAutoloopLocalTargetSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 22, value);
};


/**
 * optional string account = 23;
 * @return {string}
 */
proto.looprpc.LiquidityParameters.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional AddressType account_addr_type = 24;
 * @return {!proto.looprpc.AddressType}
 */
proto.looprpc.LiquidityParameters.prototype.getAccountAddrType = function() {
  return /** @type {!proto.looprpc.AddressType} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.looprpc.AddressType} value
 * @return {!proto.looprpc.LiquidityParameters} returns this
 */
proto.looprpc.LiquidityParameters.prototype.setAccountAddrType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.LiquidityRule.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.LiquidityRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.LiquidityRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LiquidityRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    swapType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pubkey: msg.getPubkey_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    incomingThreshold: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outgoingThreshold: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.LiquidityRule}
 */
proto.looprpc.LiquidityRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.LiquidityRule;
  return proto.looprpc.LiquidityRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.LiquidityRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.LiquidityRule}
 */
proto.looprpc.LiquidityRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChannelId(value);
      break;
    case 6:
      var value = /** @type {!proto.looprpc.SwapType} */ (reader.readEnum());
      msg.setSwapType(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPubkey(value);
      break;
    case 2:
      var value = /** @type {!proto.looprpc.LiquidityRuleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIncomingThreshold(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOutgoingThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.LiquidityRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.LiquidityRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.LiquidityRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.LiquidityRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getSwapType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIncomingThreshold();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getOutgoingThreshold();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint64 channel_id = 1;
 * @return {string}
 */
proto.looprpc.LiquidityRule.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional SwapType swap_type = 6;
 * @return {!proto.looprpc.SwapType}
 */
proto.looprpc.LiquidityRule.prototype.getSwapType = function() {
  return /** @type {!proto.looprpc.SwapType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.looprpc.SwapType} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setSwapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bytes pubkey = 5;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.LiquidityRule.prototype.getPubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes pubkey = 5;
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {string}
 */
proto.looprpc.LiquidityRule.prototype.getPubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPubkey()));
};


/**
 * optional bytes pubkey = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {!Uint8Array}
 */
proto.looprpc.LiquidityRule.prototype.getPubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPubkey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setPubkey = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional LiquidityRuleType type = 2;
 * @return {!proto.looprpc.LiquidityRuleType}
 */
proto.looprpc.LiquidityRule.prototype.getType = function() {
  return /** @type {!proto.looprpc.LiquidityRuleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.looprpc.LiquidityRuleType} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 incoming_threshold = 3;
 * @return {number}
 */
proto.looprpc.LiquidityRule.prototype.getIncomingThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setIncomingThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 outgoing_threshold = 4;
 * @return {number}
 */
proto.looprpc.LiquidityRule.prototype.getOutgoingThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.LiquidityRule} returns this
 */
proto.looprpc.LiquidityRule.prototype.setOutgoingThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SetLiquidityParamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SetLiquidityParamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SetLiquidityParamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SetLiquidityParamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parameters: (f = msg.getParameters()) && proto.looprpc.LiquidityParameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SetLiquidityParamsRequest}
 */
proto.looprpc.SetLiquidityParamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SetLiquidityParamsRequest;
  return proto.looprpc.SetLiquidityParamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SetLiquidityParamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SetLiquidityParamsRequest}
 */
proto.looprpc.SetLiquidityParamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.LiquidityParameters;
      reader.readMessage(value,proto.looprpc.LiquidityParameters.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SetLiquidityParamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SetLiquidityParamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SetLiquidityParamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SetLiquidityParamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.looprpc.LiquidityParameters.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiquidityParameters parameters = 1;
 * @return {?proto.looprpc.LiquidityParameters}
 */
proto.looprpc.SetLiquidityParamsRequest.prototype.getParameters = function() {
  return /** @type{?proto.looprpc.LiquidityParameters} */ (
    jspb.Message.getWrapperField(this, proto.looprpc.LiquidityParameters, 1));
};


/**
 * @param {?proto.looprpc.LiquidityParameters|undefined} value
 * @return {!proto.looprpc.SetLiquidityParamsRequest} returns this
*/
proto.looprpc.SetLiquidityParamsRequest.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.looprpc.SetLiquidityParamsRequest} returns this
 */
proto.looprpc.SetLiquidityParamsRequest.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.looprpc.SetLiquidityParamsRequest.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SetLiquidityParamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SetLiquidityParamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SetLiquidityParamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SetLiquidityParamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SetLiquidityParamsResponse}
 */
proto.looprpc.SetLiquidityParamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SetLiquidityParamsResponse;
  return proto.looprpc.SetLiquidityParamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SetLiquidityParamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SetLiquidityParamsResponse}
 */
proto.looprpc.SetLiquidityParamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SetLiquidityParamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SetLiquidityParamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SetLiquidityParamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SetLiquidityParamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SuggestSwapsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SuggestSwapsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SuggestSwapsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SuggestSwapsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SuggestSwapsRequest}
 */
proto.looprpc.SuggestSwapsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SuggestSwapsRequest;
  return proto.looprpc.SuggestSwapsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SuggestSwapsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SuggestSwapsRequest}
 */
proto.looprpc.SuggestSwapsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SuggestSwapsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SuggestSwapsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SuggestSwapsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SuggestSwapsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.Disqualified.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.Disqualified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.Disqualified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.Disqualified.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    pubkey: msg.getPubkey_asB64(),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.Disqualified}
 */
proto.looprpc.Disqualified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.Disqualified;
  return proto.looprpc.Disqualified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.Disqualified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.Disqualified}
 */
proto.looprpc.Disqualified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPubkey(value);
      break;
    case 2:
      var value = /** @type {!proto.looprpc.AutoReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.Disqualified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.Disqualified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.Disqualified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.Disqualified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getPubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint64 channel_id = 1;
 * @return {string}
 */
proto.looprpc.Disqualified.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.Disqualified} returns this
 */
proto.looprpc.Disqualified.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional bytes pubkey = 3;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.Disqualified.prototype.getPubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes pubkey = 3;
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {string}
 */
proto.looprpc.Disqualified.prototype.getPubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPubkey()));
};


/**
 * optional bytes pubkey = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPubkey()`
 * @return {!Uint8Array}
 */
proto.looprpc.Disqualified.prototype.getPubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPubkey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.Disqualified} returns this
 */
proto.looprpc.Disqualified.prototype.setPubkey = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional AutoReason reason = 2;
 * @return {!proto.looprpc.AutoReason}
 */
proto.looprpc.Disqualified.prototype.getReason = function() {
  return /** @type {!proto.looprpc.AutoReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.looprpc.AutoReason} value
 * @return {!proto.looprpc.Disqualified} returns this
 */
proto.looprpc.Disqualified.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.SuggestSwapsResponse.repeatedFields_ = [1,3,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.SuggestSwapsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.SuggestSwapsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.SuggestSwapsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SuggestSwapsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    loopOutList: jspb.Message.toObjectList(msg.getLoopOutList(),
    proto.looprpc.LoopOutRequest.toObject, includeInstance),
    loopInList: jspb.Message.toObjectList(msg.getLoopInList(),
    proto.looprpc.LoopInRequest.toObject, includeInstance),
    disqualifiedList: jspb.Message.toObjectList(msg.getDisqualifiedList(),
    proto.looprpc.Disqualified.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.SuggestSwapsResponse}
 */
proto.looprpc.SuggestSwapsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.SuggestSwapsResponse;
  return proto.looprpc.SuggestSwapsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.SuggestSwapsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.SuggestSwapsResponse}
 */
proto.looprpc.SuggestSwapsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.LoopOutRequest;
      reader.readMessage(value,proto.looprpc.LoopOutRequest.deserializeBinaryFromReader);
      msg.addLoopOut(value);
      break;
    case 3:
      var value = new proto.looprpc.LoopInRequest;
      reader.readMessage(value,proto.looprpc.LoopInRequest.deserializeBinaryFromReader);
      msg.addLoopIn(value);
      break;
    case 2:
      var value = new proto.looprpc.Disqualified;
      reader.readMessage(value,proto.looprpc.Disqualified.deserializeBinaryFromReader);
      msg.addDisqualified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.SuggestSwapsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.SuggestSwapsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.SuggestSwapsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.SuggestSwapsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoopOutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.LoopOutRequest.serializeBinaryToWriter
    );
  }
  f = message.getLoopInList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.looprpc.LoopInRequest.serializeBinaryToWriter
    );
  }
  f = message.getDisqualifiedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.looprpc.Disqualified.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoopOutRequest loop_out = 1;
 * @return {!Array<!proto.looprpc.LoopOutRequest>}
 */
proto.looprpc.SuggestSwapsResponse.prototype.getLoopOutList = function() {
  return /** @type{!Array<!proto.looprpc.LoopOutRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.LoopOutRequest, 1));
};


/**
 * @param {!Array<!proto.looprpc.LoopOutRequest>} value
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
*/
proto.looprpc.SuggestSwapsResponse.prototype.setLoopOutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.LoopOutRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.LoopOutRequest}
 */
proto.looprpc.SuggestSwapsResponse.prototype.addLoopOut = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.LoopOutRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
 */
proto.looprpc.SuggestSwapsResponse.prototype.clearLoopOutList = function() {
  return this.setLoopOutList([]);
};


/**
 * repeated LoopInRequest loop_in = 3;
 * @return {!Array<!proto.looprpc.LoopInRequest>}
 */
proto.looprpc.SuggestSwapsResponse.prototype.getLoopInList = function() {
  return /** @type{!Array<!proto.looprpc.LoopInRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.LoopInRequest, 3));
};


/**
 * @param {!Array<!proto.looprpc.LoopInRequest>} value
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
*/
proto.looprpc.SuggestSwapsResponse.prototype.setLoopInList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.looprpc.LoopInRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.LoopInRequest}
 */
proto.looprpc.SuggestSwapsResponse.prototype.addLoopIn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.looprpc.LoopInRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
 */
proto.looprpc.SuggestSwapsResponse.prototype.clearLoopInList = function() {
  return this.setLoopInList([]);
};


/**
 * repeated Disqualified disqualified = 2;
 * @return {!Array<!proto.looprpc.Disqualified>}
 */
proto.looprpc.SuggestSwapsResponse.prototype.getDisqualifiedList = function() {
  return /** @type{!Array<!proto.looprpc.Disqualified>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.Disqualified, 2));
};


/**
 * @param {!Array<!proto.looprpc.Disqualified>} value
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
*/
proto.looprpc.SuggestSwapsResponse.prototype.setDisqualifiedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.looprpc.Disqualified=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.Disqualified}
 */
proto.looprpc.SuggestSwapsResponse.prototype.addDisqualified = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.looprpc.Disqualified, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.SuggestSwapsResponse} returns this
 */
proto.looprpc.SuggestSwapsResponse.prototype.clearDisqualifiedList = function() {
  return this.setDisqualifiedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.AbandonSwapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.AbandonSwapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.AbandonSwapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.AbandonSwapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    iKnowWhatIAmDoing: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.AbandonSwapRequest}
 */
proto.looprpc.AbandonSwapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.AbandonSwapRequest;
  return proto.looprpc.AbandonSwapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.AbandonSwapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.AbandonSwapRequest}
 */
proto.looprpc.AbandonSwapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIKnowWhatIAmDoing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.AbandonSwapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.AbandonSwapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.AbandonSwapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.AbandonSwapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getIKnowWhatIAmDoing();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.AbandonSwapRequest.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.looprpc.AbandonSwapRequest.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.looprpc.AbandonSwapRequest.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.AbandonSwapRequest} returns this
 */
proto.looprpc.AbandonSwapRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool i_know_what_i_am_doing = 2;
 * @return {boolean}
 */
proto.looprpc.AbandonSwapRequest.prototype.getIKnowWhatIAmDoing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.looprpc.AbandonSwapRequest} returns this
 */
proto.looprpc.AbandonSwapRequest.prototype.setIKnowWhatIAmDoing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.AbandonSwapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.AbandonSwapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.AbandonSwapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.AbandonSwapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.AbandonSwapResponse}
 */
proto.looprpc.AbandonSwapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.AbandonSwapResponse;
  return proto.looprpc.AbandonSwapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.AbandonSwapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.AbandonSwapResponse}
 */
proto.looprpc.AbandonSwapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.AbandonSwapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.AbandonSwapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.AbandonSwapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.AbandonSwapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListReservationsRequest}
 */
proto.looprpc.ListReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListReservationsRequest;
  return proto.looprpc.ListReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListReservationsRequest}
 */
proto.looprpc.ListReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.ListReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.looprpc.ClientReservation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListReservationsResponse}
 */
proto.looprpc.ListReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListReservationsResponse;
  return proto.looprpc.ListReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListReservationsResponse}
 */
proto.looprpc.ListReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.ClientReservation;
      reader.readMessage(value,proto.looprpc.ClientReservation.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.ClientReservation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClientReservation reservations = 1;
 * @return {!Array<!proto.looprpc.ClientReservation>}
 */
proto.looprpc.ListReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.looprpc.ClientReservation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.ClientReservation, 1));
};


/**
 * @param {!Array<!proto.looprpc.ClientReservation>} value
 * @return {!proto.looprpc.ListReservationsResponse} returns this
*/
proto.looprpc.ListReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.ClientReservation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.ClientReservation}
 */
proto.looprpc.ListReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.ClientReservation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.ListReservationsResponse} returns this
 */
proto.looprpc.ListReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ClientReservation.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ClientReservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ClientReservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ClientReservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: msg.getReservationId_asB64(),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    txId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vout: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expiry: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ClientReservation}
 */
proto.looprpc.ClientReservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ClientReservation;
  return proto.looprpc.ClientReservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ClientReservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ClientReservation}
 */
proto.looprpc.ClientReservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVout(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ClientReservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ClientReservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ClientReservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ClientReservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVout();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getExpiry();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional bytes reservation_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.ClientReservation.prototype.getReservationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes reservation_id = 1;
 * This is a type-conversion wrapper around `getReservationId()`
 * @return {string}
 */
proto.looprpc.ClientReservation.prototype.getReservationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReservationId()));
};


/**
 * optional bytes reservation_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReservationId()`
 * @return {!Uint8Array}
 */
proto.looprpc.ClientReservation.prototype.getReservationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReservationId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.looprpc.ClientReservation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 amount = 3;
 * @return {string}
 */
proto.looprpc.ClientReservation.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional string tx_id = 4;
 * @return {string}
 */
proto.looprpc.ClientReservation.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 vout = 5;
 * @return {number}
 */
proto.looprpc.ClientReservation.prototype.getVout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setVout = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 expiry = 6;
 * @return {number}
 */
proto.looprpc.ClientReservation.prototype.getExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.ClientReservation} returns this
 */
proto.looprpc.ClientReservation.prototype.setExpiry = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.InstantOutRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InstantOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InstantOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InstantOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationIdsList: msg.getReservationIdsList_asB64(),
    outgoingChanSetList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    destAddr: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InstantOutRequest}
 */
proto.looprpc.InstantOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InstantOutRequest;
  return proto.looprpc.InstantOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InstantOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InstantOutRequest}
 */
proto.looprpc.InstantOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addReservationIds(value);
      break;
    case 2:
      var values = /** @type {!Array<string>} */ (reader.isDelimited() ? reader.readPackedUint64String() : [reader.readUint64String()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutgoingChanSet(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InstantOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InstantOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
  f = message.getOutgoingChanSetList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      2,
      f
    );
  }
  f = message.getDestAddr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated bytes reservation_ids = 1;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.looprpc.InstantOutRequest.prototype.getReservationIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes reservation_ids = 1;
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<string>}
 */
proto.looprpc.InstantOutRequest.prototype.getReservationIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getReservationIdsList()));
};


/**
 * repeated bytes reservation_ids = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.looprpc.InstantOutRequest.prototype.getReservationIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getReservationIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.setReservationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.addReservationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.clearReservationIdsList = function() {
  return this.setReservationIdsList([]);
};


/**
 * repeated uint64 outgoing_chan_set = 2;
 * @return {!Array<string>}
 */
proto.looprpc.InstantOutRequest.prototype.getOutgoingChanSetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.setOutgoingChanSetList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.addOutgoingChanSet = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.clearOutgoingChanSetList = function() {
  return this.setOutgoingChanSetList([]);
};


/**
 * optional string dest_addr = 3;
 * @return {string}
 */
proto.looprpc.InstantOutRequest.prototype.getDestAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutRequest} returns this
 */
proto.looprpc.InstantOutRequest.prototype.setDestAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InstantOutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InstantOutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InstantOutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instantOutHash: msg.getInstantOutHash_asB64(),
    sweepTxId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InstantOutResponse}
 */
proto.looprpc.InstantOutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InstantOutResponse;
  return proto.looprpc.InstantOutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InstantOutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InstantOutResponse}
 */
proto.looprpc.InstantOutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setInstantOutHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSweepTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InstantOutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InstantOutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstantOutHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSweepTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bytes instant_out_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.InstantOutResponse.prototype.getInstantOutHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes instant_out_hash = 1;
 * This is a type-conversion wrapper around `getInstantOutHash()`
 * @return {string}
 */
proto.looprpc.InstantOutResponse.prototype.getInstantOutHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getInstantOutHash()));
};


/**
 * optional bytes instant_out_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInstantOutHash()`
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOutResponse.prototype.getInstantOutHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getInstantOutHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.InstantOutResponse} returns this
 */
proto.looprpc.InstantOutResponse.prototype.setInstantOutHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string sweep_tx_id = 2;
 * @return {string}
 */
proto.looprpc.InstantOutResponse.prototype.getSweepTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutResponse} returns this
 */
proto.looprpc.InstantOutResponse.prototype.setSweepTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.looprpc.InstantOutResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutResponse} returns this
 */
proto.looprpc.InstantOutResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InstantOutQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InstantOutQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InstantOutQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    numReservations: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InstantOutQuoteRequest}
 */
proto.looprpc.InstantOutQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InstantOutQuoteRequest;
  return proto.looprpc.InstantOutQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InstantOutQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InstantOutQuoteRequest}
 */
proto.looprpc.InstantOutQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumReservations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOutQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InstantOutQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InstantOutQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getNumReservations();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional uint64 amt = 1;
 * @return {string}
 */
proto.looprpc.InstantOutQuoteRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutQuoteRequest} returns this
 */
proto.looprpc.InstantOutQuoteRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int32 num_reservations = 2;
 * @return {number}
 */
proto.looprpc.InstantOutQuoteRequest.prototype.getNumReservations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.looprpc.InstantOutQuoteRequest} returns this
 */
proto.looprpc.InstantOutQuoteRequest.prototype.setNumReservations = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InstantOutQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InstantOutQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InstantOutQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceFeeSat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    sweepFeeSat: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InstantOutQuoteResponse}
 */
proto.looprpc.InstantOutQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InstantOutQuoteResponse;
  return proto.looprpc.InstantOutQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InstantOutQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InstantOutQuoteResponse}
 */
proto.looprpc.InstantOutQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setServiceFeeSat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSweepFeeSat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOutQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InstantOutQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InstantOutQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOutQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getSweepFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
};


/**
 * optional int64 service_fee_sat = 1;
 * @return {string}
 */
proto.looprpc.InstantOutQuoteResponse.prototype.getServiceFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutQuoteResponse} returns this
 */
proto.looprpc.InstantOutQuoteResponse.prototype.setServiceFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 sweep_fee_sat = 2;
 * @return {string}
 */
proto.looprpc.InstantOutQuoteResponse.prototype.getSweepFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOutQuoteResponse} returns this
 */
proto.looprpc.InstantOutQuoteResponse.prototype.setSweepFeeSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListInstantOutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListInstantOutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListInstantOutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListInstantOutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListInstantOutsRequest}
 */
proto.looprpc.ListInstantOutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListInstantOutsRequest;
  return proto.looprpc.ListInstantOutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListInstantOutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListInstantOutsRequest}
 */
proto.looprpc.ListInstantOutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListInstantOutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListInstantOutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListInstantOutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListInstantOutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.ListInstantOutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.ListInstantOutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.ListInstantOutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.ListInstantOutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListInstantOutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapsList: jspb.Message.toObjectList(msg.getSwapsList(),
    proto.looprpc.InstantOut.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.ListInstantOutsResponse}
 */
proto.looprpc.ListInstantOutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.ListInstantOutsResponse;
  return proto.looprpc.ListInstantOutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.ListInstantOutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.ListInstantOutsResponse}
 */
proto.looprpc.ListInstantOutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.looprpc.InstantOut;
      reader.readMessage(value,proto.looprpc.InstantOut.deserializeBinaryFromReader);
      msg.addSwaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.ListInstantOutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.ListInstantOutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.ListInstantOutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.ListInstantOutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.looprpc.InstantOut.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InstantOut swaps = 1;
 * @return {!Array<!proto.looprpc.InstantOut>}
 */
proto.looprpc.ListInstantOutsResponse.prototype.getSwapsList = function() {
  return /** @type{!Array<!proto.looprpc.InstantOut>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.looprpc.InstantOut, 1));
};


/**
 * @param {!Array<!proto.looprpc.InstantOut>} value
 * @return {!proto.looprpc.ListInstantOutsResponse} returns this
*/
proto.looprpc.ListInstantOutsResponse.prototype.setSwapsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.looprpc.InstantOut=} opt_value
 * @param {number=} opt_index
 * @return {!proto.looprpc.InstantOut}
 */
proto.looprpc.ListInstantOutsResponse.prototype.addSwaps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.looprpc.InstantOut, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.ListInstantOutsResponse} returns this
 */
proto.looprpc.ListInstantOutsResponse.prototype.clearSwapsList = function() {
  return this.setSwapsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.looprpc.InstantOut.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.looprpc.InstantOut.prototype.toObject = function(opt_includeInstance) {
  return proto.looprpc.InstantOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.looprpc.InstantOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapHash: msg.getSwapHash_asB64(),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    reservationIdsList: msg.getReservationIdsList_asB64(),
    sweepTxId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.looprpc.InstantOut}
 */
proto.looprpc.InstantOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.looprpc.InstantOut;
  return proto.looprpc.InstantOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.looprpc.InstantOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.looprpc.InstantOut}
 */
proto.looprpc.InstantOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSwapHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addReservationIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSweepTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.looprpc.InstantOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.looprpc.InstantOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.looprpc.InstantOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getReservationIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      4,
      f
    );
  }
  f = message.getSweepTxId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bytes swap_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.looprpc.InstantOut.prototype.getSwapHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes swap_hash = 1;
 * This is a type-conversion wrapper around `getSwapHash()`
 * @return {string}
 */
proto.looprpc.InstantOut.prototype.getSwapHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSwapHash()));
};


/**
 * optional bytes swap_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSwapHash()`
 * @return {!Uint8Array}
 */
proto.looprpc.InstantOut.prototype.getSwapHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSwapHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.setSwapHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.looprpc.InstantOut.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 amount = 3;
 * @return {string}
 */
proto.looprpc.InstantOut.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * repeated bytes reservation_ids = 4;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.looprpc.InstantOut.prototype.getReservationIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * repeated bytes reservation_ids = 4;
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<string>}
 */
proto.looprpc.InstantOut.prototype.getReservationIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getReservationIdsList()));
};


/**
 * repeated bytes reservation_ids = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReservationIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.looprpc.InstantOut.prototype.getReservationIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getReservationIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.setReservationIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.addReservationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.clearReservationIdsList = function() {
  return this.setReservationIdsList([]);
};


/**
 * optional string sweep_tx_id = 5;
 * @return {string}
 */
proto.looprpc.InstantOut.prototype.getSweepTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.looprpc.InstantOut} returns this
 */
proto.looprpc.InstantOut.prototype.setSweepTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.looprpc.AddressType = {
  ADDRESS_TYPE_UNKNOWN: 0,
  TAPROOT_PUBKEY: 1
};

/**
 * @enum {number}
 */
proto.looprpc.SwapType = {
  LOOP_OUT: 0,
  LOOP_IN: 1
};

/**
 * @enum {number}
 */
proto.looprpc.SwapState = {
  INITIATED: 0,
  PREIMAGE_REVEALED: 1,
  HTLC_PUBLISHED: 2,
  SUCCESS: 3,
  FAILED: 4,
  INVOICE_SETTLED: 5
};

/**
 * @enum {number}
 */
proto.looprpc.FailureReason = {
  FAILURE_REASON_NONE: 0,
  FAILURE_REASON_OFFCHAIN: 1,
  FAILURE_REASON_TIMEOUT: 2,
  FAILURE_REASON_SWEEP_TIMEOUT: 3,
  FAILURE_REASON_INSUFFICIENT_VALUE: 4,
  FAILURE_REASON_TEMPORARY: 5,
  FAILURE_REASON_INCORRECT_AMOUNT: 6,
  FAILURE_REASON_ABANDONED: 7,
  FAILURE_REASON_INSUFFICIENT_CONFIRMED_BALANCE: 8,
  FAILURE_REASON_INCORRECT_HTLC_AMT_SWEPT: 9
};

/**
 * @enum {number}
 */
proto.looprpc.LiquidityRuleType = {
  UNKNOWN: 0,
  THRESHOLD: 1
};

/**
 * @enum {number}
 */
proto.looprpc.AutoReason = {
  AUTO_REASON_UNKNOWN: 0,
  AUTO_REASON_BUDGET_NOT_STARTED: 1,
  AUTO_REASON_SWEEP_FEES: 2,
  AUTO_REASON_BUDGET_ELAPSED: 3,
  AUTO_REASON_IN_FLIGHT: 4,
  AUTO_REASON_SWAP_FEE: 5,
  AUTO_REASON_MINER_FEE: 6,
  AUTO_REASON_PREPAY: 7,
  AUTO_REASON_FAILURE_BACKOFF: 8,
  AUTO_REASON_LOOP_OUT: 9,
  AUTO_REASON_LOOP_IN: 10,
  AUTO_REASON_LIQUIDITY_OK: 11,
  AUTO_REASON_BUDGET_INSUFFICIENT: 12,
  AUTO_REASON_FEE_INSUFFICIENT: 13
};

goog.object.extend(exports, proto.looprpc);
