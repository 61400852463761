/* eslint-disable */
var proto = { litrpc: {} };

// source: lit-sessions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.litrpc.AddSessionRequest', null, global);
goog.exportSymbol('proto.litrpc.AddSessionResponse', null, global);
goog.exportSymbol('proto.litrpc.ChannelConstraint', null, global);
goog.exportSymbol('proto.litrpc.ChannelPolicyBounds', null, global);
goog.exportSymbol('proto.litrpc.ChannelRestrict', null, global);
goog.exportSymbol('proto.litrpc.HistoryLimit', null, global);
goog.exportSymbol('proto.litrpc.ListSessionsRequest', null, global);
goog.exportSymbol('proto.litrpc.ListSessionsResponse', null, global);
goog.exportSymbol('proto.litrpc.MacaroonPermission', null, global);
goog.exportSymbol('proto.litrpc.MacaroonRecipe', null, global);
goog.exportSymbol('proto.litrpc.OffChainBudget', null, global);
goog.exportSymbol('proto.litrpc.OnChainBudget', null, global);
goog.exportSymbol('proto.litrpc.PeerRestrict', null, global);
goog.exportSymbol('proto.litrpc.Rate', null, global);
goog.exportSymbol('proto.litrpc.RateLimit', null, global);
goog.exportSymbol('proto.litrpc.RevokeSessionRequest', null, global);
goog.exportSymbol('proto.litrpc.RevokeSessionResponse', null, global);
goog.exportSymbol('proto.litrpc.RuleValue', null, global);
goog.exportSymbol('proto.litrpc.RuleValue.ValueCase', null, global);
goog.exportSymbol('proto.litrpc.RulesMap', null, global);
goog.exportSymbol('proto.litrpc.SendToSelf', null, global);
goog.exportSymbol('proto.litrpc.Session', null, global);
goog.exportSymbol('proto.litrpc.SessionState', null, global);
goog.exportSymbol('proto.litrpc.SessionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.AddSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.AddSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.litrpc.AddSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.AddSessionRequest.displayName = 'proto.litrpc.AddSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.MacaroonPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.MacaroonPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.MacaroonPermission.displayName = 'proto.litrpc.MacaroonPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.AddSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.AddSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.AddSessionResponse.displayName = 'proto.litrpc.AddSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.Session.displayName = 'proto.litrpc.Session';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.MacaroonRecipe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.MacaroonRecipe.repeatedFields_, null);
};
goog.inherits(proto.litrpc.MacaroonRecipe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.MacaroonRecipe.displayName = 'proto.litrpc.MacaroonRecipe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ListSessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.ListSessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.ListSessionsRequest.displayName = 'proto.litrpc.ListSessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ListSessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.ListSessionsResponse.repeatedFields_, null);
};
goog.inherits(proto.litrpc.ListSessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.ListSessionsResponse.displayName = 'proto.litrpc.ListSessionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RevokeSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RevokeSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.RevokeSessionRequest.displayName = 'proto.litrpc.RevokeSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RevokeSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RevokeSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.RevokeSessionResponse.displayName = 'proto.litrpc.RevokeSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RulesMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RulesMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.RulesMap.displayName = 'proto.litrpc.RulesMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RuleValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.litrpc.RuleValue.oneofGroups_);
};
goog.inherits(proto.litrpc.RuleValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.RuleValue.displayName = 'proto.litrpc.RuleValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RateLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RateLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.RateLimit.displayName = 'proto.litrpc.RateLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.Rate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.Rate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.Rate.displayName = 'proto.litrpc.Rate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.HistoryLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.HistoryLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.HistoryLimit.displayName = 'proto.litrpc.HistoryLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ChannelPolicyBounds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.ChannelPolicyBounds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.ChannelPolicyBounds.displayName = 'proto.litrpc.ChannelPolicyBounds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.OffChainBudget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.OffChainBudget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.OffChainBudget.displayName = 'proto.litrpc.OffChainBudget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.OnChainBudget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.OnChainBudget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.OnChainBudget.displayName = 'proto.litrpc.OnChainBudget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.SendToSelf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.SendToSelf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.SendToSelf.displayName = 'proto.litrpc.SendToSelf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ChannelRestrict = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.ChannelRestrict.repeatedFields_, null);
};
goog.inherits(proto.litrpc.ChannelRestrict, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.ChannelRestrict.displayName = 'proto.litrpc.ChannelRestrict';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.PeerRestrict = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.PeerRestrict.repeatedFields_, null);
};
goog.inherits(proto.litrpc.PeerRestrict, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.PeerRestrict.displayName = 'proto.litrpc.PeerRestrict';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ChannelConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.ChannelConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.litrpc.ChannelConstraint.displayName = 'proto.litrpc.ChannelConstraint';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.AddSessionRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.AddSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.AddSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.AddSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiryTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    mailboxServerAddr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    devServer: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    macaroonCustomPermissionsList: jspb.Message.toObjectList(msg.getMacaroonCustomPermissionsList(),
    proto.litrpc.MacaroonPermission.toObject, includeInstance),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.AddSessionRequest}
 */
proto.litrpc.AddSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.AddSessionRequest;
  return proto.litrpc.AddSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.AddSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.AddSessionRequest}
 */
proto.litrpc.AddSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.litrpc.SessionType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setExpiryTimestampSeconds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailboxServerAddr(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevServer(value);
      break;
    case 6:
      var value = new proto.litrpc.MacaroonPermission;
      reader.readMessage(value,proto.litrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addMacaroonCustomPermissions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.AddSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.AddSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.AddSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getExpiryTimestampSeconds();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getMailboxServerAddr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDevServer();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMacaroonCustomPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.litrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionType session_type = 2;
 * @return {!proto.litrpc.SessionType}
 */
proto.litrpc.AddSessionRequest.prototype.getSessionType = function() {
  return /** @type {!proto.litrpc.SessionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.litrpc.SessionType} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 expiry_timestamp_seconds = 3;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getExpiryTimestampSeconds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setExpiryTimestampSeconds = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional string mailbox_server_addr = 4;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getMailboxServerAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setMailboxServerAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool dev_server = 5;
 * @return {boolean}
 */
proto.litrpc.AddSessionRequest.prototype.getDevServer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setDevServer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated MacaroonPermission macaroon_custom_permissions = 6;
 * @return {!Array<!proto.litrpc.MacaroonPermission>}
 */
proto.litrpc.AddSessionRequest.prototype.getMacaroonCustomPermissionsList = function() {
  return /** @type{!Array<!proto.litrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.litrpc.MacaroonPermission, 6));
};


/**
 * @param {!Array<!proto.litrpc.MacaroonPermission>} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
*/
proto.litrpc.AddSessionRequest.prototype.setMacaroonCustomPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.litrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.AddSessionRequest.prototype.addMacaroonCustomPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.litrpc.MacaroonPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.clearMacaroonCustomPermissionsList = function() {
  return this.setMacaroonCustomPermissionsList([]);
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.AddSessionRequest} returns this
 */
proto.litrpc.AddSessionRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.MacaroonPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.MacaroonPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.MacaroonPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.MacaroonPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.MacaroonPermission;
  return proto.litrpc.MacaroonPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.MacaroonPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.MacaroonPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.MacaroonPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.MacaroonPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.MacaroonPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity = 1;
 * @return {string}
 */
proto.litrpc.MacaroonPermission.prototype.getEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.MacaroonPermission} returns this
 */
proto.litrpc.MacaroonPermission.prototype.setEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.litrpc.MacaroonPermission.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.MacaroonPermission} returns this
 */
proto.litrpc.MacaroonPermission.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.AddSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.AddSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.AddSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.litrpc.Session.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.AddSessionResponse}
 */
proto.litrpc.AddSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.AddSessionResponse;
  return proto.litrpc.AddSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.AddSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.AddSessionResponse}
 */
proto.litrpc.AddSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.Session;
      reader.readMessage(value,proto.litrpc.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.AddSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.AddSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.AddSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.litrpc.Session.serializeBinaryToWriter
    );
  }
};


/**
 * optional Session session = 1;
 * @return {?proto.litrpc.Session}
 */
proto.litrpc.AddSessionResponse.prototype.getSession = function() {
  return /** @type{?proto.litrpc.Session} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.Session, 1));
};


/**
 * @param {?proto.litrpc.Session|undefined} value
 * @return {!proto.litrpc.AddSessionResponse} returns this
*/
proto.litrpc.AddSessionResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.AddSessionResponse} returns this
 */
proto.litrpc.AddSessionResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.AddSessionResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Session.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expiryTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    mailboxServerAddr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    devServer: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    pairingSecret: msg.getPairingSecret_asB64(),
    pairingSecretMnemonic: jspb.Message.getFieldWithDefault(msg, 8, ""),
    localPublicKey: msg.getLocalPublicKey_asB64(),
    remotePublicKey: msg.getRemotePublicKey_asB64(),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    macaroonRecipe: (f = msg.getMacaroonRecipe()) && proto.litrpc.MacaroonRecipe.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    autopilotFeatureInfoMap: (f = msg.getAutopilotFeatureInfoMap()) ? f.toObject(includeInstance, proto.litrpc.RulesMap.toObject) : [],
    revokedAt: jspb.Message.getFieldWithDefault(msg, 16, "0"),
    groupId: msg.getGroupId_asB64(),
    featureConfigsMap: (f = msg.getFeatureConfigsMap()) ? f.toObject(includeInstance, undefined) : [],
    privacyFlags: jspb.Message.getFieldWithDefault(msg, 19, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.Session;
  return proto.litrpc.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.litrpc.SessionState} */ (reader.readEnum());
      msg.setSessionState(value);
      break;
    case 3:
      var value = /** @type {!proto.litrpc.SessionType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setExpiryTimestampSeconds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailboxServerAddr(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevServer(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPairingSecret(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPairingSecretMnemonic(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLocalPublicKey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRemotePublicKey(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = new proto.litrpc.MacaroonRecipe;
      reader.readMessage(value,proto.litrpc.MacaroonRecipe.deserializeBinaryFromReader);
      msg.setMacaroonRecipe(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 15:
      var value = msg.getAutopilotFeatureInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.litrpc.RulesMap.deserializeBinaryFromReader, "", new proto.litrpc.RulesMap());
         });
      break;
    case 16:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRevokedAt(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 18:
      var value = msg.getFeatureConfigsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 19:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setPrivacyFlags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getExpiryTimestampSeconds();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getMailboxServerAddr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDevServer();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPairingSecret_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getPairingSecretMnemonic();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocalPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getRemotePublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
  f = message.getMacaroonRecipe();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.litrpc.MacaroonRecipe.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAutopilotFeatureInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.litrpc.RulesMap.serializeBinaryToWriter);
  }
  f = message.getRevokedAt();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      16,
      f
    );
  }
  f = message.getGroupId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getFeatureConfigsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getPrivacyFlags();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      19,
      f
    );
  }
};


/**
 * optional bytes id = 14;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes id = 14;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setId = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.litrpc.Session.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionState session_state = 2;
 * @return {!proto.litrpc.SessionState}
 */
proto.litrpc.Session.prototype.getSessionState = function() {
  return /** @type {!proto.litrpc.SessionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.litrpc.SessionState} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setSessionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SessionType session_type = 3;
 * @return {!proto.litrpc.SessionType}
 */
proto.litrpc.Session.prototype.getSessionType = function() {
  return /** @type {!proto.litrpc.SessionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.litrpc.SessionType} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 expiry_timestamp_seconds = 4;
 * @return {string}
 */
proto.litrpc.Session.prototype.getExpiryTimestampSeconds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setExpiryTimestampSeconds = function(value) {
  return jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional string mailbox_server_addr = 5;
 * @return {string}
 */
proto.litrpc.Session.prototype.getMailboxServerAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setMailboxServerAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool dev_server = 6;
 * @return {boolean}
 */
proto.litrpc.Session.prototype.getDevServer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setDevServer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bytes pairing_secret = 7;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getPairingSecret = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes pairing_secret = 7;
 * This is a type-conversion wrapper around `getPairingSecret()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getPairingSecret_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPairingSecret()));
};


/**
 * optional bytes pairing_secret = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPairingSecret()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getPairingSecret_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPairingSecret()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setPairingSecret = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional string pairing_secret_mnemonic = 8;
 * @return {string}
 */
proto.litrpc.Session.prototype.getPairingSecretMnemonic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setPairingSecretMnemonic = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bytes local_public_key = 9;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getLocalPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes local_public_key = 9;
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getLocalPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLocalPublicKey()));
};


/**
 * optional bytes local_public_key = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getLocalPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLocalPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setLocalPublicKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};


/**
 * optional bytes remote_public_key = 10;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getRemotePublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes remote_public_key = 10;
 * This is a type-conversion wrapper around `getRemotePublicKey()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getRemotePublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRemotePublicKey()));
};


/**
 * optional bytes remote_public_key = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRemotePublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getRemotePublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRemotePublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setRemotePublicKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * optional uint64 created_at = 11;
 * @return {string}
 */
proto.litrpc.Session.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional MacaroonRecipe macaroon_recipe = 12;
 * @return {?proto.litrpc.MacaroonRecipe}
 */
proto.litrpc.Session.prototype.getMacaroonRecipe = function() {
  return /** @type{?proto.litrpc.MacaroonRecipe} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.MacaroonRecipe, 12));
};


/**
 * @param {?proto.litrpc.MacaroonRecipe|undefined} value
 * @return {!proto.litrpc.Session} returns this
*/
proto.litrpc.Session.prototype.setMacaroonRecipe = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.clearMacaroonRecipe = function() {
  return this.setMacaroonRecipe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.Session.prototype.hasMacaroonRecipe = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string account_id = 13;
 * @return {string}
 */
proto.litrpc.Session.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * map<string, RulesMap> autopilot_feature_info = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.litrpc.RulesMap>}
 */
proto.litrpc.Session.prototype.getAutopilotFeatureInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.litrpc.RulesMap>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      proto.litrpc.RulesMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.clearAutopilotFeatureInfoMap = function() {
  this.getAutopilotFeatureInfoMap().clear();
  return this;
};


/**
 * optional uint64 revoked_at = 16;
 * @return {string}
 */
proto.litrpc.Session.prototype.getRevokedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setRevokedAt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 16, value);
};


/**
 * optional bytes group_id = 17;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes group_id = 17;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * map<string, string> feature_configs = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.litrpc.Session.prototype.getFeatureConfigsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.clearFeatureConfigsMap = function() {
  this.getFeatureConfigsMap().clear();
  return this;
};


/**
 * optional uint64 privacy_flags = 19;
 * @return {string}
 */
proto.litrpc.Session.prototype.getPrivacyFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.Session} returns this
 */
proto.litrpc.Session.prototype.setPrivacyFlags = function(value) {
  return jspb.Message.setProto3StringIntField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.MacaroonRecipe.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.MacaroonRecipe.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.MacaroonRecipe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.MacaroonRecipe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonRecipe.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.litrpc.MacaroonPermission.toObject, includeInstance),
    caveatsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.MacaroonRecipe}
 */
proto.litrpc.MacaroonRecipe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.MacaroonRecipe;
  return proto.litrpc.MacaroonRecipe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.MacaroonRecipe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.MacaroonRecipe}
 */
proto.litrpc.MacaroonRecipe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.MacaroonPermission;
      reader.readMessage(value,proto.litrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCaveats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.MacaroonRecipe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.MacaroonRecipe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.MacaroonRecipe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonRecipe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.litrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
  f = message.getCaveatsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated MacaroonPermission permissions = 1;
 * @return {!Array<!proto.litrpc.MacaroonPermission>}
 */
proto.litrpc.MacaroonRecipe.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.litrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.litrpc.MacaroonPermission, 1));
};


/**
 * @param {!Array<!proto.litrpc.MacaroonPermission>} value
 * @return {!proto.litrpc.MacaroonRecipe} returns this
*/
proto.litrpc.MacaroonRecipe.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.litrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.MacaroonRecipe.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.litrpc.MacaroonPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.MacaroonRecipe} returns this
 */
proto.litrpc.MacaroonRecipe.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated string caveats = 2;
 * @return {!Array<string>}
 */
proto.litrpc.MacaroonRecipe.prototype.getCaveatsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.litrpc.MacaroonRecipe} returns this
 */
proto.litrpc.MacaroonRecipe.prototype.setCaveatsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.litrpc.MacaroonRecipe} returns this
 */
proto.litrpc.MacaroonRecipe.prototype.addCaveats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.MacaroonRecipe} returns this
 */
proto.litrpc.MacaroonRecipe.prototype.clearCaveatsList = function() {
  return this.setCaveatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ListSessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ListSessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ListSessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ListSessionsRequest}
 */
proto.litrpc.ListSessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ListSessionsRequest;
  return proto.litrpc.ListSessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ListSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ListSessionsRequest}
 */
proto.litrpc.ListSessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ListSessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ListSessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ListSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.ListSessionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ListSessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ListSessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ListSessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.litrpc.Session.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ListSessionsResponse}
 */
proto.litrpc.ListSessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ListSessionsResponse;
  return proto.litrpc.ListSessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ListSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ListSessionsResponse}
 */
proto.litrpc.ListSessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.Session;
      reader.readMessage(value,proto.litrpc.Session.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ListSessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ListSessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ListSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.litrpc.Session.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Session sessions = 1;
 * @return {!Array<!proto.litrpc.Session>}
 */
proto.litrpc.ListSessionsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.litrpc.Session>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.litrpc.Session, 1));
};


/**
 * @param {!Array<!proto.litrpc.Session>} value
 * @return {!proto.litrpc.ListSessionsResponse} returns this
*/
proto.litrpc.ListSessionsResponse.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.litrpc.Session=} opt_value
 * @param {number=} opt_index
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.ListSessionsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.litrpc.Session, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.ListSessionsResponse} returns this
 */
proto.litrpc.ListSessionsResponse.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RevokeSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RevokeSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RevokeSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    localPublicKey: msg.getLocalPublicKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RevokeSessionRequest}
 */
proto.litrpc.RevokeSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RevokeSessionRequest;
  return proto.litrpc.RevokeSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RevokeSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RevokeSessionRequest}
 */
proto.litrpc.RevokeSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLocalPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RevokeSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RevokeSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
};


/**
 * optional bytes local_public_key = 8;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes local_public_key = 8;
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {string}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLocalPublicKey()));
};


/**
 * optional bytes local_public_key = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLocalPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.litrpc.RevokeSessionRequest} returns this
 */
proto.litrpc.RevokeSessionRequest.prototype.setLocalPublicKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RevokeSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RevokeSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RevokeSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RevokeSessionResponse}
 */
proto.litrpc.RevokeSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RevokeSessionResponse;
  return proto.litrpc.RevokeSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RevokeSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RevokeSessionResponse}
 */
proto.litrpc.RevokeSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RevokeSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RevokeSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RulesMap.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RulesMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RulesMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RulesMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesMap: (f = msg.getRulesMap()) ? f.toObject(includeInstance, proto.litrpc.RuleValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RulesMap}
 */
proto.litrpc.RulesMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RulesMap;
  return proto.litrpc.RulesMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RulesMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RulesMap}
 */
proto.litrpc.RulesMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getRulesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.litrpc.RuleValue.deserializeBinaryFromReader, "", new proto.litrpc.RuleValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RulesMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RulesMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RulesMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RulesMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.litrpc.RuleValue.serializeBinaryToWriter);
  }
};


/**
 * map<string, RuleValue> rules = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.litrpc.RuleValue>}
 */
proto.litrpc.RulesMap.prototype.getRulesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.litrpc.RuleValue>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.litrpc.RuleValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.litrpc.RulesMap} returns this
 */
proto.litrpc.RulesMap.prototype.clearRulesMap = function() {
  this.getRulesMap().clear();
  return this;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.litrpc.RuleValue.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.litrpc.RuleValue.ValueCase = {
  VALUE_NOT_SET: 0,
  RATE_LIMIT: 1,
  CHAN_POLICY_BOUNDS: 2,
  HISTORY_LIMIT: 3,
  OFF_CHAIN_BUDGET: 4,
  ON_CHAIN_BUDGET: 5,
  SEND_TO_SELF: 6,
  CHANNEL_RESTRICT: 7,
  PEER_RESTRICT: 8,
  CHANNEL_CONSTRAINT: 9
};

/**
 * @return {proto.litrpc.RuleValue.ValueCase}
 */
proto.litrpc.RuleValue.prototype.getValueCase = function() {
  return /** @type {proto.litrpc.RuleValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.litrpc.RuleValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RuleValue.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RuleValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RuleValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RuleValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    rateLimit: (f = msg.getRateLimit()) && proto.litrpc.RateLimit.toObject(includeInstance, f),
    chanPolicyBounds: (f = msg.getChanPolicyBounds()) && proto.litrpc.ChannelPolicyBounds.toObject(includeInstance, f),
    historyLimit: (f = msg.getHistoryLimit()) && proto.litrpc.HistoryLimit.toObject(includeInstance, f),
    offChainBudget: (f = msg.getOffChainBudget()) && proto.litrpc.OffChainBudget.toObject(includeInstance, f),
    onChainBudget: (f = msg.getOnChainBudget()) && proto.litrpc.OnChainBudget.toObject(includeInstance, f),
    sendToSelf: (f = msg.getSendToSelf()) && proto.litrpc.SendToSelf.toObject(includeInstance, f),
    channelRestrict: (f = msg.getChannelRestrict()) && proto.litrpc.ChannelRestrict.toObject(includeInstance, f),
    peerRestrict: (f = msg.getPeerRestrict()) && proto.litrpc.PeerRestrict.toObject(includeInstance, f),
    channelConstraint: (f = msg.getChannelConstraint()) && proto.litrpc.ChannelConstraint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RuleValue}
 */
proto.litrpc.RuleValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RuleValue;
  return proto.litrpc.RuleValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RuleValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RuleValue}
 */
proto.litrpc.RuleValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.RateLimit;
      reader.readMessage(value,proto.litrpc.RateLimit.deserializeBinaryFromReader);
      msg.setRateLimit(value);
      break;
    case 2:
      var value = new proto.litrpc.ChannelPolicyBounds;
      reader.readMessage(value,proto.litrpc.ChannelPolicyBounds.deserializeBinaryFromReader);
      msg.setChanPolicyBounds(value);
      break;
    case 3:
      var value = new proto.litrpc.HistoryLimit;
      reader.readMessage(value,proto.litrpc.HistoryLimit.deserializeBinaryFromReader);
      msg.setHistoryLimit(value);
      break;
    case 4:
      var value = new proto.litrpc.OffChainBudget;
      reader.readMessage(value,proto.litrpc.OffChainBudget.deserializeBinaryFromReader);
      msg.setOffChainBudget(value);
      break;
    case 5:
      var value = new proto.litrpc.OnChainBudget;
      reader.readMessage(value,proto.litrpc.OnChainBudget.deserializeBinaryFromReader);
      msg.setOnChainBudget(value);
      break;
    case 6:
      var value = new proto.litrpc.SendToSelf;
      reader.readMessage(value,proto.litrpc.SendToSelf.deserializeBinaryFromReader);
      msg.setSendToSelf(value);
      break;
    case 7:
      var value = new proto.litrpc.ChannelRestrict;
      reader.readMessage(value,proto.litrpc.ChannelRestrict.deserializeBinaryFromReader);
      msg.setChannelRestrict(value);
      break;
    case 8:
      var value = new proto.litrpc.PeerRestrict;
      reader.readMessage(value,proto.litrpc.PeerRestrict.deserializeBinaryFromReader);
      msg.setPeerRestrict(value);
      break;
    case 9:
      var value = new proto.litrpc.ChannelConstraint;
      reader.readMessage(value,proto.litrpc.ChannelConstraint.deserializeBinaryFromReader);
      msg.setChannelConstraint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RuleValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RuleValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RuleValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RuleValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRateLimit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.litrpc.RateLimit.serializeBinaryToWriter
    );
  }
  f = message.getChanPolicyBounds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.litrpc.ChannelPolicyBounds.serializeBinaryToWriter
    );
  }
  f = message.getHistoryLimit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.litrpc.HistoryLimit.serializeBinaryToWriter
    );
  }
  f = message.getOffChainBudget();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.litrpc.OffChainBudget.serializeBinaryToWriter
    );
  }
  f = message.getOnChainBudget();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.litrpc.OnChainBudget.serializeBinaryToWriter
    );
  }
  f = message.getSendToSelf();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.litrpc.SendToSelf.serializeBinaryToWriter
    );
  }
  f = message.getChannelRestrict();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.litrpc.ChannelRestrict.serializeBinaryToWriter
    );
  }
  f = message.getPeerRestrict();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.litrpc.PeerRestrict.serializeBinaryToWriter
    );
  }
  f = message.getChannelConstraint();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.litrpc.ChannelConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional RateLimit rate_limit = 1;
 * @return {?proto.litrpc.RateLimit}
 */
proto.litrpc.RuleValue.prototype.getRateLimit = function() {
  return /** @type{?proto.litrpc.RateLimit} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.RateLimit, 1));
};


/**
 * @param {?proto.litrpc.RateLimit|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setRateLimit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearRateLimit = function() {
  return this.setRateLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasRateLimit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChannelPolicyBounds chan_policy_bounds = 2;
 * @return {?proto.litrpc.ChannelPolicyBounds}
 */
proto.litrpc.RuleValue.prototype.getChanPolicyBounds = function() {
  return /** @type{?proto.litrpc.ChannelPolicyBounds} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.ChannelPolicyBounds, 2));
};


/**
 * @param {?proto.litrpc.ChannelPolicyBounds|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setChanPolicyBounds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearChanPolicyBounds = function() {
  return this.setChanPolicyBounds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasChanPolicyBounds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HistoryLimit history_limit = 3;
 * @return {?proto.litrpc.HistoryLimit}
 */
proto.litrpc.RuleValue.prototype.getHistoryLimit = function() {
  return /** @type{?proto.litrpc.HistoryLimit} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.HistoryLimit, 3));
};


/**
 * @param {?proto.litrpc.HistoryLimit|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setHistoryLimit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearHistoryLimit = function() {
  return this.setHistoryLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasHistoryLimit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OffChainBudget off_chain_budget = 4;
 * @return {?proto.litrpc.OffChainBudget}
 */
proto.litrpc.RuleValue.prototype.getOffChainBudget = function() {
  return /** @type{?proto.litrpc.OffChainBudget} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.OffChainBudget, 4));
};


/**
 * @param {?proto.litrpc.OffChainBudget|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setOffChainBudget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearOffChainBudget = function() {
  return this.setOffChainBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasOffChainBudget = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OnChainBudget on_chain_budget = 5;
 * @return {?proto.litrpc.OnChainBudget}
 */
proto.litrpc.RuleValue.prototype.getOnChainBudget = function() {
  return /** @type{?proto.litrpc.OnChainBudget} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.OnChainBudget, 5));
};


/**
 * @param {?proto.litrpc.OnChainBudget|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setOnChainBudget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearOnChainBudget = function() {
  return this.setOnChainBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasOnChainBudget = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SendToSelf send_to_self = 6;
 * @return {?proto.litrpc.SendToSelf}
 */
proto.litrpc.RuleValue.prototype.getSendToSelf = function() {
  return /** @type{?proto.litrpc.SendToSelf} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.SendToSelf, 6));
};


/**
 * @param {?proto.litrpc.SendToSelf|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setSendToSelf = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearSendToSelf = function() {
  return this.setSendToSelf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasSendToSelf = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChannelRestrict channel_restrict = 7;
 * @return {?proto.litrpc.ChannelRestrict}
 */
proto.litrpc.RuleValue.prototype.getChannelRestrict = function() {
  return /** @type{?proto.litrpc.ChannelRestrict} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.ChannelRestrict, 7));
};


/**
 * @param {?proto.litrpc.ChannelRestrict|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setChannelRestrict = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearChannelRestrict = function() {
  return this.setChannelRestrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasChannelRestrict = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PeerRestrict peer_restrict = 8;
 * @return {?proto.litrpc.PeerRestrict}
 */
proto.litrpc.RuleValue.prototype.getPeerRestrict = function() {
  return /** @type{?proto.litrpc.PeerRestrict} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.PeerRestrict, 8));
};


/**
 * @param {?proto.litrpc.PeerRestrict|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setPeerRestrict = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearPeerRestrict = function() {
  return this.setPeerRestrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasPeerRestrict = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ChannelConstraint channel_constraint = 9;
 * @return {?proto.litrpc.ChannelConstraint}
 */
proto.litrpc.RuleValue.prototype.getChannelConstraint = function() {
  return /** @type{?proto.litrpc.ChannelConstraint} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.ChannelConstraint, 9));
};


/**
 * @param {?proto.litrpc.ChannelConstraint|undefined} value
 * @return {!proto.litrpc.RuleValue} returns this
*/
proto.litrpc.RuleValue.prototype.setChannelConstraint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.litrpc.RuleValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RuleValue} returns this
 */
proto.litrpc.RuleValue.prototype.clearChannelConstraint = function() {
  return this.setChannelConstraint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RuleValue.prototype.hasChannelConstraint = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RateLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RateLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RateLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RateLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    readLimit: (f = msg.getReadLimit()) && proto.litrpc.Rate.toObject(includeInstance, f),
    writeLimit: (f = msg.getWriteLimit()) && proto.litrpc.Rate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RateLimit}
 */
proto.litrpc.RateLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RateLimit;
  return proto.litrpc.RateLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RateLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RateLimit}
 */
proto.litrpc.RateLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.Rate;
      reader.readMessage(value,proto.litrpc.Rate.deserializeBinaryFromReader);
      msg.setReadLimit(value);
      break;
    case 2:
      var value = new proto.litrpc.Rate;
      reader.readMessage(value,proto.litrpc.Rate.deserializeBinaryFromReader);
      msg.setWriteLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RateLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RateLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RateLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RateLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReadLimit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.litrpc.Rate.serializeBinaryToWriter
    );
  }
  f = message.getWriteLimit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.litrpc.Rate.serializeBinaryToWriter
    );
  }
};


/**
 * optional Rate read_limit = 1;
 * @return {?proto.litrpc.Rate}
 */
proto.litrpc.RateLimit.prototype.getReadLimit = function() {
  return /** @type{?proto.litrpc.Rate} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.Rate, 1));
};


/**
 * @param {?proto.litrpc.Rate|undefined} value
 * @return {!proto.litrpc.RateLimit} returns this
*/
proto.litrpc.RateLimit.prototype.setReadLimit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RateLimit} returns this
 */
proto.litrpc.RateLimit.prototype.clearReadLimit = function() {
  return this.setReadLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RateLimit.prototype.hasReadLimit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Rate write_limit = 2;
 * @return {?proto.litrpc.Rate}
 */
proto.litrpc.RateLimit.prototype.getWriteLimit = function() {
  return /** @type{?proto.litrpc.Rate} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.Rate, 2));
};


/**
 * @param {?proto.litrpc.Rate|undefined} value
 * @return {!proto.litrpc.RateLimit} returns this
*/
proto.litrpc.RateLimit.prototype.setWriteLimit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.litrpc.RateLimit} returns this
 */
proto.litrpc.RateLimit.prototype.clearWriteLimit = function() {
  return this.setWriteLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.litrpc.RateLimit.prototype.hasWriteLimit = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.Rate.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.Rate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.Rate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Rate.toObject = function(includeInstance, msg) {
  var f, obj = {
    iterations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numHours: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.Rate}
 */
proto.litrpc.Rate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.Rate;
  return proto.litrpc.Rate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.Rate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.Rate}
 */
proto.litrpc.Rate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIterations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.Rate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.Rate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.Rate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Rate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIterations();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNumHours();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 iterations = 1;
 * @return {number}
 */
proto.litrpc.Rate.prototype.getIterations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.Rate} returns this
 */
proto.litrpc.Rate.prototype.setIterations = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 num_hours = 2;
 * @return {number}
 */
proto.litrpc.Rate.prototype.getNumHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.Rate} returns this
 */
proto.litrpc.Rate.prototype.setNumHours = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.HistoryLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.HistoryLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.HistoryLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.HistoryLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    duration: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.HistoryLimit}
 */
proto.litrpc.HistoryLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.HistoryLimit;
  return proto.litrpc.HistoryLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.HistoryLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.HistoryLimit}
 */
proto.litrpc.HistoryLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.HistoryLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.HistoryLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.HistoryLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.HistoryLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getDuration();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
};


/**
 * optional uint64 start_time = 1;
 * @return {string}
 */
proto.litrpc.HistoryLimit.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.HistoryLimit} returns this
 */
proto.litrpc.HistoryLimit.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 duration = 2;
 * @return {string}
 */
proto.litrpc.HistoryLimit.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.HistoryLimit} returns this
 */
proto.litrpc.HistoryLimit.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ChannelPolicyBounds.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ChannelPolicyBounds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ChannelPolicyBounds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelPolicyBounds.toObject = function(includeInstance, msg) {
  var f, obj = {
    minBaseMsat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    maxBaseMsat: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    minRatePpm: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxRatePpm: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minCltvDelta: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxCltvDelta: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minHtlcMsat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    maxHtlcMsat: jspb.Message.getFieldWithDefault(msg, 8, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ChannelPolicyBounds}
 */
proto.litrpc.ChannelPolicyBounds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ChannelPolicyBounds;
  return proto.litrpc.ChannelPolicyBounds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ChannelPolicyBounds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ChannelPolicyBounds}
 */
proto.litrpc.ChannelPolicyBounds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinBaseMsat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxBaseMsat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinRatePpm(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRatePpm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinCltvDelta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxCltvDelta(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinHtlcMsat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxHtlcMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ChannelPolicyBounds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ChannelPolicyBounds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ChannelPolicyBounds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelPolicyBounds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinBaseMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getMaxBaseMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getMinRatePpm();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMaxRatePpm();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinCltvDelta();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMaxCltvDelta();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getMinHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getMaxHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      8,
      f
    );
  }
};


/**
 * optional uint64 min_base_msat = 1;
 * @return {string}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMinBaseMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMinBaseMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 max_base_msat = 2;
 * @return {string}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMaxBaseMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMaxBaseMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint32 min_rate_ppm = 3;
 * @return {number}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMinRatePpm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMinRatePpm = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 max_rate_ppm = 4;
 * @return {number}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMaxRatePpm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMaxRatePpm = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_cltv_delta = 5;
 * @return {number}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMinCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMinCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 max_cltv_delta = 6;
 * @return {number}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMaxCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMaxCltvDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 min_htlc_msat = 7;
 * @return {string}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMinHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMinHtlcMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint64 max_htlc_msat = 8;
 * @return {string}
 */
proto.litrpc.ChannelPolicyBounds.prototype.getMaxHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelPolicyBounds} returns this
 */
proto.litrpc.ChannelPolicyBounds.prototype.setMaxHtlcMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.OffChainBudget.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.OffChainBudget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.OffChainBudget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.OffChainBudget.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxAmtMsat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    maxFeesMsat: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.OffChainBudget}
 */
proto.litrpc.OffChainBudget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.OffChainBudget;
  return proto.litrpc.OffChainBudget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.OffChainBudget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.OffChainBudget}
 */
proto.litrpc.OffChainBudget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxAmtMsat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxFeesMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.OffChainBudget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.OffChainBudget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.OffChainBudget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.OffChainBudget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getMaxFeesMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
};


/**
 * optional uint64 max_amt_msat = 1;
 * @return {string}
 */
proto.litrpc.OffChainBudget.prototype.getMaxAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.OffChainBudget} returns this
 */
proto.litrpc.OffChainBudget.prototype.setMaxAmtMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 max_fees_msat = 2;
 * @return {string}
 */
proto.litrpc.OffChainBudget.prototype.getMaxFeesMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.OffChainBudget} returns this
 */
proto.litrpc.OffChainBudget.prototype.setMaxFeesMsat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.OnChainBudget.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.OnChainBudget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.OnChainBudget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.OnChainBudget.toObject = function(includeInstance, msg) {
  var f, obj = {
    absoluteAmtSats: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    maxSatPerVByte: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.OnChainBudget}
 */
proto.litrpc.OnChainBudget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.OnChainBudget;
  return proto.litrpc.OnChainBudget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.OnChainBudget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.OnChainBudget}
 */
proto.litrpc.OnChainBudget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAbsoluteAmtSats(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxSatPerVByte(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.OnChainBudget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.OnChainBudget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.OnChainBudget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.OnChainBudget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbsoluteAmtSats();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getMaxSatPerVByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
};


/**
 * optional uint64 absolute_amt_sats = 1;
 * @return {string}
 */
proto.litrpc.OnChainBudget.prototype.getAbsoluteAmtSats = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.OnChainBudget} returns this
 */
proto.litrpc.OnChainBudget.prototype.setAbsoluteAmtSats = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 max_sat_per_v_byte = 2;
 * @return {string}
 */
proto.litrpc.OnChainBudget.prototype.getMaxSatPerVByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.OnChainBudget} returns this
 */
proto.litrpc.OnChainBudget.prototype.setMaxSatPerVByte = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.SendToSelf.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.SendToSelf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.SendToSelf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.SendToSelf.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.SendToSelf}
 */
proto.litrpc.SendToSelf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.SendToSelf;
  return proto.litrpc.SendToSelf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.SendToSelf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.SendToSelf}
 */
proto.litrpc.SendToSelf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.SendToSelf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.SendToSelf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.SendToSelf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.SendToSelf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.ChannelRestrict.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ChannelRestrict.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ChannelRestrict.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ChannelRestrict} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelRestrict.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ChannelRestrict}
 */
proto.litrpc.ChannelRestrict.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ChannelRestrict;
  return proto.litrpc.ChannelRestrict.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ChannelRestrict} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ChannelRestrict}
 */
proto.litrpc.ChannelRestrict.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<string>} */ (reader.isDelimited() ? reader.readPackedUint64String() : [reader.readUint64String()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannelIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ChannelRestrict.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ChannelRestrict.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ChannelRestrict} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelRestrict.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelIdsList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      1,
      f
    );
  }
};


/**
 * repeated uint64 channel_ids = 1;
 * @return {!Array<string>}
 */
proto.litrpc.ChannelRestrict.prototype.getChannelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.litrpc.ChannelRestrict} returns this
 */
proto.litrpc.ChannelRestrict.prototype.setChannelIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.litrpc.ChannelRestrict} returns this
 */
proto.litrpc.ChannelRestrict.prototype.addChannelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.ChannelRestrict} returns this
 */
proto.litrpc.ChannelRestrict.prototype.clearChannelIdsList = function() {
  return this.setChannelIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.PeerRestrict.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.PeerRestrict.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.PeerRestrict.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.PeerRestrict} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.PeerRestrict.toObject = function(includeInstance, msg) {
  var f, obj = {
    peerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.PeerRestrict}
 */
proto.litrpc.PeerRestrict.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.PeerRestrict;
  return proto.litrpc.PeerRestrict.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.PeerRestrict} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.PeerRestrict}
 */
proto.litrpc.PeerRestrict.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPeerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.PeerRestrict.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.PeerRestrict.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.PeerRestrict} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.PeerRestrict.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string peer_ids = 1;
 * @return {!Array<string>}
 */
proto.litrpc.PeerRestrict.prototype.getPeerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.litrpc.PeerRestrict} returns this
 */
proto.litrpc.PeerRestrict.prototype.setPeerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.litrpc.PeerRestrict} returns this
 */
proto.litrpc.PeerRestrict.prototype.addPeerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.litrpc.PeerRestrict} returns this
 */
proto.litrpc.PeerRestrict.prototype.clearPeerIdsList = function() {
  return this.setPeerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ChannelConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ChannelConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ChannelConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    minCapacitySat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    maxCapacitySat: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    maxPushSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    privateAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    publicAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ChannelConstraint}
 */
proto.litrpc.ChannelConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ChannelConstraint;
  return proto.litrpc.ChannelConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ChannelConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ChannelConstraint}
 */
proto.litrpc.ChannelConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinCapacitySat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxCapacitySat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxPushSat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateAllowed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicAllowed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ChannelConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ChannelConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ChannelConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ChannelConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinCapacitySat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getMaxCapacitySat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getMaxPushSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getPrivateAllowed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPublicAllowed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint64 min_capacity_sat = 1;
 * @return {string}
 */
proto.litrpc.ChannelConstraint.prototype.getMinCapacitySat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelConstraint} returns this
 */
proto.litrpc.ChannelConstraint.prototype.setMinCapacitySat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 max_capacity_sat = 2;
 * @return {string}
 */
proto.litrpc.ChannelConstraint.prototype.getMaxCapacitySat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelConstraint} returns this
 */
proto.litrpc.ChannelConstraint.prototype.setMaxCapacitySat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 max_push_sat = 3;
 * @return {string}
 */
proto.litrpc.ChannelConstraint.prototype.getMaxPushSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.litrpc.ChannelConstraint} returns this
 */
proto.litrpc.ChannelConstraint.prototype.setMaxPushSat = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bool private_allowed = 4;
 * @return {boolean}
 */
proto.litrpc.ChannelConstraint.prototype.getPrivateAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.litrpc.ChannelConstraint} returns this
 */
proto.litrpc.ChannelConstraint.prototype.setPrivateAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool public_allowed = 5;
 * @return {boolean}
 */
proto.litrpc.ChannelConstraint.prototype.getPublicAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.litrpc.ChannelConstraint} returns this
 */
proto.litrpc.ChannelConstraint.prototype.setPublicAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.litrpc.SessionType = {
  TYPE_MACAROON_READONLY: 0,
  TYPE_MACAROON_ADMIN: 1,
  TYPE_MACAROON_CUSTOM: 2,
  TYPE_UI_PASSWORD: 3,
  TYPE_AUTOPILOT: 4,
  TYPE_MACAROON_ACCOUNT: 5
};

/**
 * @enum {number}
 */
proto.litrpc.SessionState = {
  STATE_CREATED: 0,
  STATE_IN_USE: 1,
  STATE_REVOKED: 2,
  STATE_EXPIRED: 3
};

goog.object.extend(exports, proto.litrpc);
